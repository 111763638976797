import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import AccountPage from '@/components/settings/AccountPage.vue';
import { useStore } from '@/store/store';
import ReservationCart from '@/components/reservation/ReservationCart.vue';
import CreateBlock from '@/components/reservation/ReservationBlockPage.vue';
//import CalendarPage from '@/components/calendarTape/CalendarPage.vue';
import CreateReservation from '@/components/reservation/ReservationCreate.vue';
import TestPage from '@/views/TestPage.vue';
import NotificationPage from '@/components/notifications/NotificationPage.vue';
import SupportLogin from '@/views/SupportLoginPage.vue';
import FileManagerPage from '@/components/settings/FileManagerPage.vue';
import ReservationViewPage from '@/components/reservation/ReservationViewPage.vue';
import WorkOrderPage from '@/components/workOrders/WorkOrderPage.vue';
import GuestsPage from '@/components/guests/GuestsPage.vue';
import ReportsPage from '@/components/reports/ReportsPage.vue';
import DocumentsPage from '@/components/owner/DocumentsPage.vue';
import { basicAlert } from '@/services/AlertController';
import { accessToken, refreshToken } from '@/services/OAuth2';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home/:pathMatch(.*)*',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/account/:pathMatch(.*)*',
        name: 'Account',
        component: AccountPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/cart/:pathMatch(.*)*',
        name: 'ReservationCart',
        component: ReservationCart,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/reservationsList/:pathMatch(.*)*',
        name: 'ReservationsList',
        component: ReservationViewPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/createBlock/:pathMatch(.*)*',
        name: 'CreateBlock',
        component: CreateBlock,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/calendarPage/:pathMatch(.*)*',
        name: 'CalendarPage',
        component: () => import('@/components/calendarTape/CalendarPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/createReservation/:pathMatch(.*)*',
        name: 'CreateReservation',
        component: CreateReservation,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/testPage/:pathMatch(.*)*',
        name: 'TestPage',
        component: TestPage,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/notifications/:pathMatch(.*)*',
        name: 'Notifications',
        component: NotificationPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login/:pathMatch(.*)*',
        name: 'Login',
        component: SupportLogin,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/fileManager/:pathMatch(.*)*',
        name: 'FileManagerPage',
        component: FileManagerPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/unifiedInbox/:pathMatch(.*)*',
        name: 'UnifiedInboxPage',
        component: () => import('@/components/inbox/UnifiedInboxPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/workOrders/:pathMatch(.*)*',
        name: 'WorkOrderPage',
        component: WorkOrderPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/guests/:pathMatch(.*)*',
        name: 'GuestsPage',
        component: GuestsPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/reports/:pathMatch(.*)*',
        name: 'ReportsPage',
        component: ReportsPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/owner/documents/:pathMatch(.*)*',
        name: 'DocumentsPage',
        component: DocumentsPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: '/home?notFound=true',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach(async (to, from, next) => {
    const store = useStore();
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.requireLogin) {
            if (!isAuthenticated()) {
                next('/home');
            } else {
                if (from.matched.some((record) => record.path == '/calendarPage')) {
                    window.location.href = to.path;
                }
                next();
            }
        } else {
            next();
        }
    } else {
        next();
    }
});

router.afterEach(async (to, from) => {
    const store = useStore();
    if (router.currentRoute.value) {
        if (to.name == 'UnifiedInboxPage') {
            store.changeActivePage('/unifiedInbox');
        }
        if (router.currentRoute.value.query.notFound == 'true') {
            basicAlert('Page Not Available', '', 'The app currently does not support this feature.');
            router.replace('/home');
        }
    }
});

function isAuthenticated() {
    if (accessToken.value) {
        return true;
    } else {
        return false;
    }
}

export default router;
