<template>
    <modal-base-layout
        title="Damage Notices Report"
        background="rgb(239, 246, 255)"
        noPaddingBottom
        customStyles="height: 100%;"
    >
        <GridComponent v-if="rowData && colDefs" :rowData="rowData" :colDefs="colDefs" />

        <ion-modal
            trigger="open-modal"
            :initial-breakpoint="1"
            :breakpoints="[0, 1]"
            ref="damageNoticesReportFilterModal"
        >
            <div class="block">
                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        overflow-x: auto;
                        height: 100%;
                        width: 100%;
                    "
                >
                    <div
                        style="
                            width: 100%;
                            background-color: #20469b;
                            padding: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        "
                    >
                        <ion-label style="color: white; font-weight: bold; font-size: 18px">Report Filters</ion-label>
                        <ion-icon
                            style="color: white; font-size: 24px; cursor: pointer"
                            :icon="close"
                            @click="dismiss()"
                        />
                    </div>
                    <ion-list style="width: 100%" lines="full">
                        <ion-item>
                            <ion-select
                                label="Properties"
                                @ionChange="fetchReport"
                                multiple
                                v-model="propertiesSelected"
                                placeholder="All Properties"
                            >
                                <ion-select-option
                                    v-for="property in properties"
                                    :value="property.property_id"
                                    :key="property.property_id"
                                >
                                    {{ property.name }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-select
                                class="filter-button"
                                v-model="timeframe"
                                label="Timeframe"
                                @ionChange="fetchReport"
                            >
                                <ion-select-option value="Yesterday">Yesterday</ion-select-option>
                                <ion-select-option value="Today">Today</ion-select-option>
                                <ion-select-option value="Last Week">Last Week</ion-select-option>
                                <ion-select-option value="This Week">This Week</ion-select-option>
                                <ion-select-option value="Last Month">Last Month</ion-select-option>
                                <ion-select-option value="This Month">This Month</ion-select-option>
                                <ion-select-option value="Last Year">Last Year</ion-select-option>
                                <ion-select-option value="This Year">This Year</ion-select-option>
                                <ion-select-option value="Future">Future</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-list>
                </div>
            </div>
        </ion-modal>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="medium" @click="cancel()">Cancel</ion-button>
                    </ion-col>

                    <ion-col style="max-width: fit-content !important">
                        <ion-button id="open-modal" style="width: fit-content !important">
                            <ion-icon style="width: 24px; height: 24px" :icon="filter"></ion-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { hideLoading, showLoading } from '@/services/Loading';
    import { errorToast } from '@/services/Toast';
    import { timeframeCalculations } from '@/utils/constants';
    import { format } from 'date-fns';
    import GridComponent from '@/components/GridComponent.vue';
    import { close, filter } from 'ionicons/icons';

    export default defineComponent({
        components: {
            GridComponent,
        },
        setup(props) {
            const store = useStore();
            const properties = ref();
            const propertiesSelected = ref([]);
            const timeframe = ref('This Month');
            const rowData = ref();
            const damageNoticesReportFilterModal = ref();
            const dismiss = () => damageNoticesReportFilterModal.value.$el.dismiss();

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            const confirm = async () => {
                return modalController.dismiss({}, 'confirm');
            };

            onMounted(async () => {
                await fetchReport();
            });

            const fetchReport = async () => {
                const loading = await showLoading('Loading...');
                try {
                    const propertiesString = propertiesSelected.value.join(',');

                    const calcTimeframe = {
                        fromDateString: '',
                        toDateString: '',
                    };

                    const { fromDate, toDate } = (
                        timeframeCalculations[timeframe.value] || timeframeCalculations['This Month']
                    )();

                    calcTimeframe.fromDateString = format(fromDate, 'yyyy-MM-dd');
                    calcTimeframe.toDateString = format(toDate, 'yyyy-MM-dd');
                    const data = await AppAPI.getDamageNoticesReport(
                        calcTimeframe.fromDateString,
                        calcTimeframe.toDateString,
                        propertiesString
                    );
                    rowData.value = data.data;
                    const p_data = await AppAPI.getDamageNoticesReportProperties();
                    properties.value = p_data.data;
                } catch (e) {
                    console.error(e);
                    errorToast('Error loading report data');
                    cancel();
                } finally {
                    hideLoading(loading);
                }
            };

            const colDefs = computed(() => {
                return [
                    {
                        field: 'tech_reservation.rental_property.id',
                        headerName: 'P. ID',
                        minWidth: 70,
                    },
                    {
                        field: 'created',
                        headerName: 'Created On',
                        type: 'dateTimeColumn',
                        minWidth: 150,
                        maxWidth: 160,
                    },
                    {
                        field: 'tech_reservation.meta_reservation.id',
                        headerName: 'Inv. ID',
                        minWidth: 100,
                        cellRenderer: ({ data }: { data: any }) => {
                            return `<a target="_self" style="text-decoration: underline;" href="/reservationsList?open=${data.tech_reservation.meta_reservation.id}">${data.tech_reservation.meta_reservation.id}</a>`;
                        },
                    },
                    {
                        field: 'work_order.number',
                        headerName: 'Work Order ID',
                        minWidth: 110,
                        cellRenderer: ({ data }: { data: any }) =>
                            data.work_order
                                ? '<a style="text-decoration: underline;" target="_self" href="/workOrders' +
                                  `?id=${data.work_order.id}">${data.work_order.number}</a>`
                                : '',
                    },
                    {
                        field: 'tech_reservation.rental_property.name',
                        headerName: 'Property',
                        minWidth: 200,
                    },
                    {
                        field: 'description',
                        headerName: 'Description',
                        autoWidthExclude: true,
                    },
                ];
            });

            const isHideUnitColumn = computed(() => {
                if (rowData.value) {
                    return rowData.value.every((item: any) => !item.room);
                } else {
                    return true;
                }
            });

            return {
                store,
                cancel,
                confirm,
                properties,
                propertiesSelected,
                fetchReport,
                timeframe,
                rowData,
                colDefs,
                damageNoticesReportFilterModal,
                dismiss,
                filter,
                close,
            };
        },
    });
</script>

<style scoped>
    .block {
        width: 100%;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ion-modal {
        --height: auto;
    }
</style>
