import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"height":"100%","padding-bottom":"env(safe-area-inset-bottom) !important","overflow":"hidden"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_manager = _resolveComponent("file-manager")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    "page-title": "Documents",
    whiteBackground: "",
    style: {"overflow":"hidden","height":"100%"}
  }, {
    default: _withCtx(() => [
      (_ctx.showDocuments)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(), _createBlock(_component_file_manager, {
              key: _ctx.fileManagerKey,
              "owner-id": _ctx.owner.id,
              features: _ctx.features,
              "read-only": _ctx.userIsOwner
            }, null, 8, ["owner-id", "features", "read-only"]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}