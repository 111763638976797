<template>
    <modal-base-layout
        title="Current Guests Report"
        background="rgb(239, 246, 255)"
        noPaddingBottom
        customStyles="height: 100%;"
    >
        <GridComponent v-if="rowData && colDefs" :rowData="rowData" :colDefs="colDefs" />

        <ion-modal
            trigger="open-modal"
            :initial-breakpoint="1"
            :breakpoints="[0, 1]"
            ref="currentGuestsReportFilterModal"
        >
            <div class="block">
                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        overflow-x: auto;
                        height: 100%;
                        width: 100%;
                    "
                >
                    <div
                        style="
                            width: 100%;
                            background-color: #20469b;
                            padding: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        "
                    >
                        <ion-label style="color: white; font-weight: bold; font-size: 18px">Report Filters</ion-label>
                        <ion-icon
                            style="color: white; font-size: 24px; cursor: pointer"
                            :icon="close"
                            @click="dismiss()"
                        />
                    </div>
                    <ion-list style="width: 100%" lines="full">
                        <ion-item>
                            <ion-select
                                class="filter-button"
                                v-model="timeframe"
                                label="Timeframe"
                                @ionChange="fetchReport"
                            >
                                <ion-select-option value="Yesterday">Yesterday</ion-select-option>
                                <ion-select-option value="Today">Today</ion-select-option>
                                <ion-select-option value="Last Week">Last Week</ion-select-option>
                                <ion-select-option value="This Week">This Week</ion-select-option>
                                <ion-select-option value="Last Month">Last Month</ion-select-option>
                                <ion-select-option value="This Month">This Month</ion-select-option>
                                <ion-select-option value="Last Year">Last Year</ion-select-option>
                                <ion-select-option value="This Year">This Year</ion-select-option>
                                <ion-select-option value="Future">Future</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-select v-model="filterValues.showDepartures" @ionChange="fetchReport" slot="end">
                                <ion-select-option :value="true">Show {{ fromDate }} Departures</ion-select-option>
                                <ion-select-option :value="false">Hide {{ fromDate }} Departures</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-list>
                </div>
            </div>
        </ion-modal>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="medium" @click="cancel()">Cancel</ion-button>
                    </ion-col>
                    <ion-col style="max-width: fit-content !important">
                        <ion-button id="open-modal" style="width: fit-content !important">
                            <ion-icon style="width: 24px; height: 24px" :icon="filter"></ion-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { hideLoading, showLoading } from '@/services/Loading';
    import { errorToast } from '@/services/Toast';
    import { escapeHTML, timeframeCalculations } from '@/utils/constants';
    import { format } from 'date-fns';
    import GridComponent from '@/components/GridComponent.vue';
    import { close, filter } from 'ionicons/icons';

    export default defineComponent({
        components: {
            GridComponent,
        },
        setup(props) {
            const store = useStore();
            const timeframe = ref('Today');
            const rowData = ref();
            const filterValues = ref({
                showDepartures: true,
            });
            const currentGuestsReportFilterModal = ref();
            const dismiss = () => currentGuestsReportFilterModal.value.$el.dismiss();

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            const confirm = async () => {
                return modalController.dismiss({}, 'confirm');
            };

            onMounted(async () => {
                await fetchReport();
            });

            const fromDate = computed(() => {
                const { fromDate, toDate } = (
                    timeframeCalculations[timeframe.value] || timeframeCalculations['This Month']
                )();
                const formattedFromDate = format(fromDate, store.userUnicodeDateFormat);
                return formattedFromDate;
            });

            const fetchReport = async () => {
                const loading = await showLoading('Loading...');
                try {
                    const calcTimeframe = {
                        fromDateString: '',
                        toDateString: '',
                    };

                    const { fromDate, toDate } = (
                        timeframeCalculations[timeframe.value] || timeframeCalculations['This Month']
                    )();

                    calcTimeframe.fromDateString = format(fromDate, 'yyyy-MM-dd');
                    calcTimeframe.toDateString = format(toDate, 'yyyy-MM-dd');
                    const data = await AppAPI.getCurrentGuestsReport(
                        calcTimeframe.fromDateString,
                        calcTimeframe.toDateString,
                        filterValues.value.showDepartures
                    );
                    rowData.value = data.data.guests;
                } catch (e) {
                    console.error(e);
                    errorToast('Error loading report data');
                    cancel();
                } finally {
                    hideLoading(loading);
                }
            };

            const colDefs = computed(() => {
                return [
                    {
                        field: 'name',
                        headerName: 'Guest Name',
                        minWidth: 200,
                        cellRenderer: ({ data }: { data: any }) => {
                            return `${data.name.replace('&amp;', '&')}</a>`;
                        },
                    },
                    {
                        field: 'email',
                        headerName: 'Email',
                        minWidth: 280,
                    },
                    {
                        field: 'phone',
                        headerName: 'Phone',
                        minWidth: 170,
                    },
                    {
                        field: 'arrival',
                        headerName: 'Arrival',
                        minWidth: 190,
                        type: 'dateTimeColumn',
                    },
                    {
                        field: 'departure',
                        headerName: 'Departure',
                        minWidth: 190,
                        type: 'dateTimeColumn',
                    },
                    {
                        field: 'property',
                        headerName: 'Property',
                        minWidth: 230,
                    },
                    {
                        field: 'unit',
                        headerName: 'Unit #',
                        minWidth: 140,
                    },
                    {
                        field: 'invoice',
                        headerName: 'Invoice #',
                        minWidth: 120,
                        cellRenderer: ({ data }: { data: any }) => {
                            return `<a target="_self" href="/reservationsList?open=${data.invoice}">${data.invoice}</a>`;
                        },
                    },
                    {
                        field: '',
                        headerName: 'Total Guests',
                        minWidth: 120,
                        valueGetter: ({ data }: { data: any }) => {
                            return `${data.adults}/${data.children}/${data.guests}`;
                        },
                    },
                ];
            });

            return {
                store,
                cancel,
                confirm,
                fetchReport,
                timeframe,
                rowData,
                colDefs,
                filterValues,
                fromDate,
                currentGuestsReportFilterModal,
                dismiss,
                close,
                filter,
            };
        },
    });
</script>

<style scoped>
    .block {
        width: 100%;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ion-modal {
        --height: auto;
    }
</style>
