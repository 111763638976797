<template>
    <modal-base-layout
        title="Housekeeping Report"
        background="rgb(239, 246, 255)"
        noPaddingBottom
        customStyles="height: 100%;"
    >
        <GridComponent v-if="rowData && colDefs" :rowData="rowData" :colDefs="colDefs" />

        <div
            v-else
            style="
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px 25px;
            "
        >
            <ion-icon :icon="documentTextOutline" style="width: 50px; height: 50px" />
            <ion-label color="#777" style="text-align: center; padding-top: 10px"
                >Click Generate to View Report</ion-label
            >
        </div>

        <ion-modal
            trigger="open-modal"
            :initial-breakpoint="1"
            :breakpoints="[0, 1]"
            ref="housekeepingReportFilterModal"
        >
            <div class="block">
                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        overflow-x: auto;
                        height: 100%;
                        width: 100%;
                    "
                >
                    <div
                        style="
                            width: 100%;
                            background-color: #20469b;
                            padding: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        "
                    >
                        <ion-label style="color: white; font-weight: bold; font-size: 18px">Report Filters</ion-label>
                        <ion-icon
                            style="color: white; font-size: 24px; cursor: pointer"
                            :icon="close"
                            @click="dismiss()"
                        />
                    </div>
                    <ion-list style="width: 100%" lines="full">
                        <ion-item>
                            <ion-select v-model="timeframe" label="Timeframe">
                                <ion-select-option value="Yesterday">Yesterday</ion-select-option>
                                <ion-select-option value="Today">Today</ion-select-option>
                                <ion-select-option value="Tomorrow">Tomorrow</ion-select-option>
                                <ion-select-option value="Last 7 Days">Last 7 Days</ion-select-option>
                                <ion-select-option value="Next 7 Days">Next 7 Days</ion-select-option>
                                <ion-select-option value="Last 14 Days">Last 14 Days</ion-select-option>
                                <ion-select-option value="Next 14 Days">Next 14 Days</ion-select-option>
                                <ion-select-option value="Last 30 Days">Last 30 Days</ion-select-option>
                                <ion-select-option value="Next 30 Days">Next 30 Days</ion-select-option>
                                <ion-select-option value="Last Year">Last Year</ion-select-option>
                                <ion-select-option value="Next Year">Next Year</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-select
                                label="Properties"
                                multiple
                                v-model="propertiesSelected"
                                placeholder="All Properties"
                            >
                                <ion-select-option
                                    v-for="property in properties"
                                    :value="property.property_id"
                                    :key="property.property_id"
                                >
                                    {{ property.name }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="cleaners.length > 0">
                            <ion-select label="Cleaners" multiple v-model="cleanersSelected" placeholder="All Cleaners">
                                <ion-select-option v-for="cleaner in cleaners" :value="cleaner.id" :key="cleaner.id">
                                    {{ cleaner.full_name }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-select label="Show Owner/iCal Blocks" v-model="filterValues.showBlocks">
                                <ion-select-option :value="true">Show</ion-select-option>
                                <ion-select-option :value="false">Hide</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-select
                                label="Show Same Day Turnovers Only"
                                v-model="filterValues.showSameDayTurnoversOnly"
                            >
                                <ion-select-option :value="true">Show</ion-select-option>
                                <ion-select-option :value="false">Hide</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-select label="Show Disabled Properties" v-model="filterValues.showDisabledProperties">
                                <ion-select-option :value="true">Show</ion-select-option>
                                <ion-select-option :value="false">Hide</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-list>
                </div>
            </div>
        </ion-modal>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="medium" @click="cancel()">Cancel</ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="fetchReport"
                            >Generate</ion-button
                        >
                    </ion-col>
                    <ion-col style="max-width: fit-content !important">
                        <ion-button id="open-modal" style="width: fit-content !important">
                            <ion-icon style="width: 24px; height: 24px" :icon="filter"></ion-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { hideLoading, showLoading } from '@/services/Loading';
    import { errorToast } from '@/services/Toast';
    import { isMediumScreen, timeframeCalculations } from '@/utils/constants';
    import { format, isAfter, isEqual } from 'date-fns';
    import GridComponent from '@/components/GridComponent.vue';
    import ArrivalInfoCellRenderer from './arrivalInfoCellRenderer.vue';
    import { close, filter, documentTextOutline } from 'ionicons/icons';

    export default defineComponent({
        components: {
            GridComponent,
        },
        setup(props) {
            const store = useStore();
            const properties = ref();
            const propertiesSelected = ref([] as any);
            const cleaners = ref([] as any);
            const cleanersSelected = ref([] as any);
            const timeframe = ref('Next 30 Days');
            const rowData = ref();
            const filterValues = ref({
                showBlocks: false,
                showSameDayTurnoversOnly: false,
                showDisabledProperties: false,
            });
            const housekeepingReportFilterModal = ref();
            const dismiss = () => housekeepingReportFilterModal.value.$el.dismiss();

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            const confirm = async () => {
                return modalController.dismiss({}, 'confirm');
            };

            onMounted(async () => {
                await fetchReport(false);
            });

            const fetchReport = async (getReport = true) => {
                const loading = await showLoading('Loading...');
                try {
                    let propertiesString = '';
                    if (propertiesSelected.value) {
                        propertiesString = propertiesSelected.value.join(',');
                    }
                    let cleanersString = '';
                    if (cleanersSelected.value) {
                        cleanersString = cleanersSelected.value.join(',');
                    }

                    const calcTimeframe = {
                        fromDateString: '',
                        toDateString: '',
                    };

                    const { fromDate, toDate } = (
                        timeframeCalculations[timeframe.value] || timeframeCalculations['This Month']
                    )();

                    calcTimeframe.fromDateString = format(fromDate, 'yyyy-MM-dd');
                    calcTimeframe.toDateString = format(toDate, 'yyyy-MM-dd');

                    if (getReport) {
                        const data = await AppAPI.getHousekeepingReport(
                            calcTimeframe.fromDateString,
                            calcTimeframe.toDateString,
                            filterValues.value.showBlocks,
                            filterValues.value.showDisabledProperties,
                            filterValues.value.showSameDayTurnoversOnly,
                            propertiesString,
                            cleanersString
                        );
                        rowData.value = data.data.items;
                    } else {
                        const p_data = await AppAPI.getHousekeepingReportProperties();
                        properties.value = p_data.data;
                        const c_data = await AppAPI.getHousekeepingReportCleaners();
                        cleaners.value = c_data.data;
                    }
                } catch (e) {
                    console.error(e);
                    errorToast('Error loading report data');
                    cancel();
                } finally {
                    hideLoading(loading);
                }
            };

            const colDefs = computed(() => {
                return [
                    {
                        field: 'guest',
                        headerName: 'Departure Info',
                        autoHeight: true,
                        //pinned: 'left',
                        width: isMediumScreen() ? undefined : 200,
                        autoWidthExclude: true,
                        cellStyle: { lineHeight: '1.2rem', padding: '0.8rem' },
                        cellRenderer: ({ data }: { data: any }) => {
                            let template = `
                            <div>
                            <div class="housekeeping-lead-text">${data.guest}</div>
                            <div>${data.to_date_time_str}</div>
                        `;
                            if (data.clean_on_date_str) {
                                template += `
                                <div>
                                    Clean on
                                    <span ${data.clean_on_date_is_custom ? 'class="text-danger"' : ''}>
                                        ${data.clean_on_date_str}
                                    </span>
                                </div>
                            `;
                            }
                            if (!!data.has_pets && !!data.number_of_pets) {
                                template += `
                                <div>
                                    Pets: ${data.number_of_pets}
                                </div>
                            `;
                            }
                            template += '</div>';
                            return template;
                        },
                        comparator: (
                            valueA: any,
                            valueB: any,
                            nodeA: {
                                data: { to_date_time: string | number | Date; clean_on_date: string | number | Date };
                            },
                            nodeB: {
                                data: { to_date_time: string | number | Date; clean_on_date: string | number | Date };
                            },
                            isDescending: any
                        ) => {
                            const toDateA = new Date(nodeA.data.to_date_time);
                            const cleanOnA = nodeA.data.clean_on_date ? new Date(nodeA.data.clean_on_date) : null;
                            const toDateB = new Date(nodeB.data.to_date_time);
                            const cleanOnB = nodeB.data.clean_on_date ? new Date(nodeB.data.clean_on_date) : null;
                            if (!!cleanOnA && !!cleanOnB) {
                                if (isEqual(cleanOnA, cleanOnB)) {
                                    if (isEqual(toDateA, toDateB)) return 0;
                                    return isAfter(toDateA, toDateB) ? 1 : -1;
                                }
                                return isAfter(cleanOnA, cleanOnB) ? 1 : -1;
                            } else if (!!cleanOnA && !cleanOnB) {
                                if (isEqual(cleanOnA, toDateB)) return 0;
                                return isAfter(cleanOnA, toDateB) ? 1 : -1;
                            } else if (!cleanOnA && !!cleanOnB) {
                                if (isEqual(toDateA, cleanOnB)) return 0;
                                return isAfter(toDateA, cleanOnB) ? 1 : -1;
                            }
                            if (isEqual(toDateA, toDateB)) return 0;
                            return isAfter(toDateA, toDateB) ? 1 : -1;
                        },
                    },
                    {
                        field: 'rental_property_name',
                        headerName: 'Property',
                    },
                    {
                        field: 'house_keeper_full_names',
                        headerName: 'Cleaner',
                        cellRenderer: ({ data }: { data: any }) => {
                            return `<span class="housekeeping-lead-text">${data.house_keeper_full_names}</span>`;
                        },
                    },
                    {
                        field: 'arrival_info',
                        headerName: 'Arrival Info',
                        width: 550,
                        sortable: false,
                        cellRenderer: ArrivalInfoCellRenderer,
                    },
                    {
                        field: '',
                        headerName: '',
                    },
                ] as any;
            });

            return {
                store,
                cancel,
                confirm,
                properties,
                propertiesSelected,
                fetchReport,
                timeframe,
                rowData,
                colDefs,
                filterValues,
                cleanersSelected,
                cleaners,
                filter,
                close,
                housekeepingReportFilterModal,
                documentTextOutline,
                dismiss,
            };
        },
    });
</script>

<style scoped>
    .block {
        width: 100%;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ion-modal {
        --height: auto;
    }
</style>
