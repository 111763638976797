<template>
    <ion-header>
        <ion-toolbar mode="md">
            <ion-title style="font-family: 'Montserrat', sans-serif; font-weight: 700; text-align: center">{{
                $props.title
            }}</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-header v-if="$slots.header" style="box-shadow: 0px 1px 5px 0px rgba(194, 194, 194, 1)">
        <ion-toolbar style="--background: white; padding: 0px !important">
            <slot name="header" />
        </ion-toolbar>
    </ion-header>

    <ion-content class="ion-no-padding" :style="{ '--background': $props.background }">
        <div :style="[$props.noPaddingBottom ? 'padding-bottom:0px;' : 'padding-bottom:20px;', $props.customStyles]">
            <slot />
        </div>
    </ion-content>

    <ion-footer v-if="$slots.footer">
        <ion-toolbar
            :style="{ '--background': $props.footerBackground }"
            style="color: black; padding: 0; padding-bottom: env(safe-area-inset-bottom) !important"
        >
            <slot name="footer" />
        </ion-toolbar>
    </ion-footer>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref, watch } from 'vue';

    export default defineComponent({
        components: {},
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            background: {
                type: String,
                required: false,
                default: 'white',
            },
            footerBackground: {
                type: String,
                required: false,
                default: 'white',
            },
            noPaddingBottom: {
                type: Boolean,
                required: false,
                default: false,
            },
            customStyles: {
                type: String,
                required: false,
                default: '',
            },
        },
        expose: ['cancel', 'confirm', 'ionRouter'],
        setup(props, ctx) {
            const ionRouter = useIonRouter();

            const cancel = (data = null as any) => {
                return modalController.dismiss(data, 'cancel');
            };

            const confirm = (data = null as any) => {
                return modalController.dismiss(data, 'confirm');
            };

            return {
                ionRouter,
                cancel,
                confirm,
            };
        },
    });
</script>
