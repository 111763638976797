<template>
    <ion-app>
        <SideMenu />
        <ion-router-outlet id="content" />
    </ion-app>
</template>

<script lang="ts">
    import { IonApp, IonRouterOutlet, useIonRouter } from '@ionic/vue';
    import { defineComponent, ref, watch } from 'vue';
    import { useStore } from './store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { addPushNotificationTapOpen } from './services/FCM';
    import { Capacitor } from '@capacitor/core';
    import { SplashScreen } from '@capacitor/splash-screen';
    import SideMenu from './components/SideMenu.vue';
    import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
    import { onMounted } from 'vue';
    import { accessToken } from './services/OAuth2';
    import LodgixOAuth2 from './services/OAuth2';
    import { errorToast } from './services/Toast';
    import { App } from '@capacitor/app';
    import router from './router';
    import { setupSentry } from '@/utils/Sentry';
    import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community';

    export default defineComponent({
        name: 'App',
        components: {
            IonApp,
            IonRouterOutlet,
            SideMenu,
        },
        setup() {
            ModuleRegistry.registerModules([AllCommunityModule]);
            const store = useStore();
            const ionRouter = useIonRouter();
            addPushNotificationTapOpen();

            App.addListener('appStateChange', async (state: any) => {
                if (state.isActive) {
                    if (router.currentRoute.value.path == '/calendarPage/') {
                        try {
                            await AppFunctions.getUserInfo(true);
                            await AppFunctions.transitionClear('/calendarPage');
                        } catch (e) {
                            console.error(e);
                        }
                    }
                    try {
                        await SplashScreen.hide();
                    } catch (e) {
                        console.error(e);
                    }
                } else {
                    // stop sentry from reporting errors when app is in background??
                }
            });

            onMounted(async () => {
                if (Capacitor.getPlatform() != 'web') {
                    await AppFunctions.setDefaultTextSize();
                    //console.log('page_url:' + JSON.stringify(window.location));

                    BranchDeepLinks.addListener('init', async (event: BranchInitEvent) => {
                        if (event.referringParams.conversation_id && event.referringParams.message_id) {
                            if (accessToken.value) {
                                if (store.FirstOpenApp != '0') {
                                    await SplashScreen.show();
                                }

                                //wait for inital api call to refresh token if needed.
                                while (!store.userEmail) {
                                    await new Promise((resolve) => setTimeout(resolve, 100));
                                    if (!accessToken.value) {
                                        break;
                                    }
                                }

                                AppFunctions.openChat(
                                    event.referringParams.conversation_id,
                                    event.referringParams.message_id
                                );
                            } else {
                                store.branchConversationId = event.referringParams.conversation_id;
                                store.branchMessageId = event.referringParams.message_id;

                                try {
                                    const value = await LodgixOAuth2.authenticateWithLodgix();
                                    if (value === 'USER_CANCELLED') {
                                        errorToast('Login Cancelled.');
                                    } else if (value == 'ADMIN_LOGIN_ERROR') {
                                        await LodgixOAuth2.logout();
                                        errorToast('Account Not Supported. Please try again.');
                                    } else {
                                        AppFunctions.openChat(store.branchConversationId, store.branchMessageId as any);
                                    }
                                } catch (error) {
                                    if (Capacitor.getPlatform() != 'web') {
                                        await LodgixOAuth2.logout();
                                        errorToast('Error logging in. Please try again.');
                                    }
                                }

                                store.branchConversationId = '';
                                store.branchMessageId = '';
                            }
                        }
                    });

                    BranchDeepLinks.addListener('initError', (error: any) => {
                        console.error('branch deep link error:', JSON.stringify(error));
                    });
                }
            });

            if (Capacitor.getPlatform() != 'ios') {
                SplashScreen.hide();
            }

            //first launch loading process of app
            if (store.FirstOpenApp == '0') {
                AppFunctions.setupProcess();
            }

            watch(
                () => store.Id,
                async (newValue, oldValue) => {
                    if (newValue != oldValue) {
                        console.log('User Changed/Loaded, setting up sentry');
                        await setupSentry();
                    }
                }
            );

            return {
                ionRouter,
            };
        },
    });
</script>
