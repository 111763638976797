import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    "page-title": "Reports",
    whiteBackground: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, { lines: "full" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedReports, (reports, category) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: category }, [
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { style: {"text-transform":"uppercase"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(category), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reports, (item, index) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: index,
                  onClick: ($event: any) => (_ctx.openModal(item.modal)),
                  style: {"cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_icon, {
                      slot: "end",
                      icon: _ctx.chevronForwardOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ], 64))
          }), 128))
        ]),
        _: 1
      }),
      (_ctx.reportsList.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ion_icon, {
              icon: _ctx.documentText,
              style: {"width":"50px","height":"50px"}
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_label, { style: {"text-align":"center","margin-top":"10px","font-weight":"bold"} }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("No reports available.")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}