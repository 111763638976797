<template>
    <base-layout page-title="Account">
        <div style="max-width: var(--max-width); text-align: left; margin: auto">
            <ion-card>
                <ion-card-content style="padding-left: 0px; padding-right: 0px">
                    <ion-card-header class="ldx-section-start" style="padding-right: 0px; padding-bottom: 0px">
                        <div style="justify-content: center; display: flex">
                            <ion-card-subtitle
                                v-if="store.userEmail != '' && store.userEmail != null"
                                style="font-size: small; text-align: center; font-weight: 700"
                                >{{ store.userEmail }}</ion-card-subtitle
                            >
                            <ion-card-subtitle
                                v-if="store.userEmail == ''"
                                style="font-size: small; font-weight: 700"
                                >{{ store.companyEmail }}</ion-card-subtitle
                            >
                        </div>
                        <div style="justify-content: center; display: flex">
                            <ion-card-title>{{ store.userFirstName }} {{ store.userLastName }}</ion-card-title>
                        </div>
                        <div style="justify-content: center; display: flex; padding-bottom: 20px">
                            <ion-thumbnail
                                style="width: 65%; height: fit-content; display: flex; justify-content: center"
                            >
                                <img
                                    v-if="store.companyLogo != '' && store.companyLogo != null"
                                    class="ldx-menu-logo"
                                    alt="Logo"
                                    :src="store.companyLogo"
                                    style="max-width: 250px; border-radius: 10px"
                                />
                                <img
                                    v-if="store.companyLogo == ''"
                                    class="ldx-menu-logo"
                                    alt="Logo"
                                    src="@/assets/img/lodgix-icon-color-2.svg"
                                    style="max-width: 250px; border-radius: 10px"
                                />
                            </ion-thumbnail>
                        </div>
                    </ion-card-header>

                    <ion-list style="padding: 0px; margin: 0px">
                        <ion-item v-if="store.companyName != '' && store.companyName != null" lines="none">
                            <ion-label style="margin: 0px; font-size: medium; max-width: 40%"
                                ><b>Company:</b></ion-label
                            >
                            <ion-label style="margin: 0px; text-align: right" text-wrap>{{
                                store.companyName
                            }}</ion-label>
                        </ion-item>
                        <ion-item v-if="store.subscriberId != '' && store.subscriberId != null" lines="none">
                            <ion-label style="margin: 0px; font-size: medium; max-width: 40%"
                                ><b>Subscriber ID:</b></ion-label
                            >
                            <ion-label style="margin: 0px; text-align: right" text-wrap>{{
                                store.subscriberId
                            }}</ion-label>
                        </ion-item>
                        <ion-item v-if="store.companyWebsite != '' && store.companyWebsite != null" lines="none">
                            <ion-label style="margin: 0px; font-size: medium; padding: 0px; max-width: 24%"
                                ><b>Website:</b></ion-label
                            >
                            <ion-label style="margin: 0px; text-align: right; padding: 0px" text-wrap>{{
                                store.companyWebsite
                            }}</ion-label>
                        </ion-item>
                        <ion-item v-if="store.companyPhone != '' && store.companyPhone != null" lines="none">
                            <ion-label style="margin: 0px; padding: 0px; font-size: medium; max-width: 24%"
                                ><b>Phone:</b></ion-label
                            >
                            <ion-label style="margin: 0px; padding: 0px; text-align: right" text-wrap>{{
                                store.companyPhone
                            }}</ion-label>
                        </ion-item>
                        <ion-item v-if="store.loginType != '' && store.loginType != null" lines="none">
                            <ion-label style="margin: 0px; padding: 0px; font-size: medium; max-width: 24%"
                                ><b>Account:</b></ion-label
                            >
                            <ion-label style="margin: 0px; padding: 0px; text-align: right" text-wrap>{{
                                store.loginType
                            }}</ion-label>
                        </ion-item>
                    </ion-list>

                    <ion-button
                        v-if="
                            getPlatform() != 'web' &&
                            (store.loginType === 'Subscriber' ||
                                store.loginType === 'Front Desk' ||
                                store.loginType === 'Super User')
                        "
                        @click="openSupport()"
                        color="primary"
                        style="
                            width: -webkit-fill-available;
                            --border-radius: 10px;
                            margin-left: 10px;
                            margin-right: 10px;
                        "
                        >Contact Support</ion-button
                    >
                </ion-card-content>
            </ion-card>

            <ion-card>
                <ion-card-header
                    style="
                        background: linear-gradient(118deg, #0d47a1, rgba(13, 71, 161, 0.7));
                        margin: 0px;
                        padding-bottom: 15px;
                    "
                >
                    <p style="font-weight: 700; font-size: large; margin: 0px; color: white">
                        <ion-icon class="ldx-header-icon" :src="phonePortrait"></ion-icon>Devices
                    </p>
                </ion-card-header>
                <ion-card-content style="padding-top: 10px">
                    <template v-for="(item, index) in deviceList" :key="index">
                        <ion-item lines="none" v-if="index < deviceShowAmount || deviceShowAmount == -1">
                            <div
                                slot="start"
                                style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: start;
                                    max-width: 70%;
                                    overflow: hidden;
                                "
                            >
                                <ion-label>{{ item.name }}</ion-label>
                            </div>
                            <div slot="end" style="display: flex; align-items: center; justify-content: start">
                                <ion-button
                                    style="margin: 0"
                                    color="white"
                                    :id="item.id"
                                    v-if="item.registration_id === store.pushNotificationToken"
                                    ><ion-icon
                                        :icon="informationCircle"
                                        color="primary"
                                        style="min-height: 20px; min-width: 20px"
                                    ></ion-icon
                                ></ion-button>
                                <ion-icon
                                    style="min-height: 20px; min-width: 20px; cursor: pointer"
                                    @click="ModalController.deviceEditModal(item)"
                                    :icon="ellipsisVerticalCircleOutline"
                                ></ion-icon>
                            </div>
                            <ion-popover
                                v-if="item.registration_id === store.pushNotificationToken"
                                :trigger="item.id"
                                side="top"
                                alignment="center"
                            >
                                <ion-content class="ion-padding" style="--background: white; text-align: center"
                                    >Current Device</ion-content
                                >
                            </ion-popover>
                            <ion-button @click="AppAPI.deleteDevice(item.registration_id)" v-if="false"
                                >Delete</ion-button
                            >
                        </ion-item>
                    </template>

                    <ion-item
                        lines="none"
                        v-if="(deviceListNextLink || deviceShowAmount > 0) && deviceList?.length > 0"
                    >
                        <ion-label @click="showMoreDevices()">View More</ion-label>
                    </ion-item>
                    <ion-label text-wrap style="margin-left: 20px" v-if="deviceList.length > 0"
                        >Devices may appear multiple times.</ion-label
                    >
                    <ion-label
                        style="
                            display: flex;
                            justify-content: center;
                            padding-top: 20px;
                            margin-bottom: 20px;
                            font-weight: 700;
                            font-size: large;
                        "
                        v-if="deviceList.length <= 0"
                        >No Devices</ion-label
                    >
                </ion-card-content>
            </ion-card>

            <ion-card>
                <ion-card-header
                    style="
                        background: linear-gradient(118deg, #0d47a1, rgba(13, 71, 161, 0.7));
                        margin: 0px;
                        padding-bottom: 15px;
                    "
                >
                    <p style="font-weight: 700; font-size: large; margin: 0px; color: white">
                        <ion-icon class="ldx-header-icon" :src="settings"></ion-icon>Settings
                    </p>
                </ion-card-header>
                <ion-card-content style="padding-top: 10px">
                    <ion-item lines="none">
                        <ion-toggle v-model="notificationToggleValue" label-placement="start"
                            >Disable Notification Warning</ion-toggle
                        >
                    </ion-item>
                    <ion-item v-if="false" lines="none">
                        <ion-label>Reset Welcome Message:</ion-label>
                        <ion-button expand="full" color="primary" slot="end" @click="resetWelcomeMessage()"
                            >Reset</ion-button
                        >
                    </ion-item>
                    <ion-item
                        lines="none"
                        v-if="getPlatform() != 'web' && (housekeeperGroup() || adminAppWelcomePopup())"
                    >
                        <ion-label text-wrap>Welcome Message</ion-label>
                        <ion-button
                            color="primary"
                            style="--border-radius: 10px; width: 20%; padding: 5px 0"
                            @click="showWelcomeMessage()"
                            >Open</ion-button
                        >
                    </ion-item>
                    <ion-item v-if="false" lines="none">
                        <ion-label>Reset Review Status:</ion-label>
                        <ion-button expand="full" color="primary" slot="end" @click="resetReviewStatus()"
                            >Reset</ion-button
                        >
                    </ion-item>
                    <ion-item lines="none" v-if="getPlatform() != 'web'">
                        <ion-button
                            color="primary"
                            style="width: 100%; --border-radius: 10px; padding: 5px 0"
                            @click="openDeviceSettings"
                            >Open Device Settings</ion-button
                        >
                    </ion-item>
                </ion-card-content>
            </ion-card>

            <ion-card>
                <ion-card-header
                    style="
                        background: linear-gradient(118deg, #0d47a1, rgba(13, 71, 161, 0.7));
                        margin: 0px;
                        padding-bottom: 15px;
                    "
                >
                    <p style="font-weight: 700; font-size: large; margin: 0px; color: white">
                        <ion-icon class="ldx-header-icon" :src="book"></ion-icon>App Information
                    </p>
                </ion-card-header>
                <ion-card-content style="padding-top: 10px">
                    <p>Lodgix Admin</p>
                    <p><a href="https://www.lodgix.com/privacy-policy/">Privacy Policy</a></p>
                    <p><a href="https://www.lodgix.com/terms-of-service/">Terms of Service</a></p>
                    <p><b>Version: </b>{{ appVersion }}</p>
                    <p><b>Build: </b>{{ appBuild }}</p>
                    <p>&copy; Lodgix {{ new Date().getFullYear() }}</p>
                </ion-card-content>
            </ion-card>

            <ion-card v-if="store.debug">
                <ion-card-header>
                    <ion-card-title>OAuth2 Information</ion-card-title>
                    <ion-card-subtitle style="font-size: small; font-weight: bold">Developer Only</ion-card-subtitle>
                </ion-card-header>
                <ion-card-content>
                    <ion-label>Access Token</ion-label>
                    <br />
                    <code style="font-weight: bolder">{{ accessToken }}</code>
                    <br />
                    <ion-label>Refresh Token</ion-label>
                    <br />
                    <code style="font-weight: bolder">{{ refreshToken }}</code>
                    <br />
                    <ion-button color="primary" expand="full" @click="logData()">LogData</ion-button>
                    <ion-button color="primary" expand="full" @click="updateRefreshToken()"
                        >Renew Access Token</ion-button
                    >
                    <ion-button color="primary" expand="full" @click="changeAccessToken()"
                        >Change Access Token</ion-button
                    >
                    <ion-button color="primary" expand="full" @click="clearAccessToken()"
                        >Clear Access Token</ion-button
                    >
                    <ion-button color="primary" expand="full" @click="clearRefreshToken()"
                        >Clear Refresh Token</ion-button
                    >
                    <ion-button color="primary" expand="full" @click="changeRefreshToken()"
                        >Change Refresh Token</ion-button
                    >
                </ion-card-content>
            </ion-card>

            <ion-card v-if="store.debug">
                <ion-card-header>
                    <ion-card-title>Push Notification Information</ion-card-title>
                    <ion-card-subtitle style="font-size: small; font-weight: bold">Developer Only</ion-card-subtitle>
                </ion-card-header>
                <ion-card-content>
                    <ion-label>Token</ion-label>
                    <br />
                    <code style="font-weight: bolder">{{ store.pushNotificationToken }}</code>

                    <br />
                    <ion-button @click="deactivateDevice(store.pushNotificationToken)">Deactivate Device</ion-button>
                </ion-card-content>
            </ion-card>

            <ion-button
                style="width: -webkit-fill-available; --border-radius: 10px; margin: 0 15px 30px 15px"
                @click="logout()"
                color="danger"
                >Sign Out</ion-button
            >
        </div>
    </base-layout>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref, watch } from 'vue';
    import { useStore } from '@/store/store';
    import LodgixOAuth2 from '@/services/OAuth2';
    import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
    import AppAPI from '@/services/AppAPI';
    import { phonePortrait, book, settings, ellipsisVerticalCircleOutline, informationCircle } from 'ionicons/icons';
    import { Intercom } from '@capacitor-community/intercom';
    import { Capacitor } from '@capacitor/core';
    import AppFunctions from '@/services/AppFunctions';
    import { deviceData } from '@/types/types';
    import { housekeeperGroup, adminAppWelcomePopup } from '@/types/accessGroups';
    import ModalController from '@/services/ModalController';
    import { accessToken, refreshToken, updateAuthTokens } from '@/services/OAuth2';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const deviceList = ref([] as deviceData[]);
            const notificationToggleValue = ref(false);
            const appVersion = ref(process.env.VUE_APP_VERSION);
            const appBuild = computed(() => {
                if (Capacitor.getPlatform() === 'ios') {
                    return process.env.VUE_APP_BUILD_IOS;
                } else if (Capacitor.getPlatform() === 'android') {
                    return process.env.VUE_APP_BUILD_ANDROID;
                } else {
                    return 'WEB';
                }
            });
            const deviceShowAmount = ref(3);
            const deviceListNextLink = ref('');

            const disableNotifications = () => {
                if (notificationToggleValue.value) {
                    store.changeDisableNotifications('yes');
                } else {
                    store.changeDisableNotifications('no');
                }
            };

            const logData = async () => {
                await AppAPI.logStatistic();
            };

            const getDevices = async () => {
                const devices = await AppAPI.getDevices('0').catch((err) => {
                    console.log(err);
                });
                if (devices) {
                    deviceList.value = devices.data.results;
                    deviceListNextLink.value = devices.data.next;
                }
            };

            const showMoreDevices = async () => {
                if (deviceShowAmount.value != -1) {
                    deviceShowAmount.value = -1;
                } else {
                    const result = await AppAPI.getDevices(deviceList.value.length.toString()).catch((err) => {
                        console.log(err);
                    });
                    if (result) {
                        deviceList.value = deviceList.value.concat(result.data.results);
                        deviceListNextLink.value = result.data.next;
                    }
                }
            };

            const openDeviceSettings = () => {
                AppFunctions.openDeviceSettings();
            };

            const logout = async () => {
                await LodgixOAuth2.logout();
            };

            const updateRefreshToken = () => {
                LodgixOAuth2.refresh();
            };

            const showWelcomeMessage = () => {
                if (adminAppWelcomePopup()) {
                    Intercom.displayCarousel({ carouselId: '32137014' });
                } else if (housekeeperGroup()) {
                    Intercom.displayCarousel({ carouselId: '33476189' });
                }
            };

            const clearRefreshToken = async () => {
                await updateAuthTokens(accessToken.value, '');
            };

            const clearAccessToken = async () => {
                await updateAuthTokens('INVALID', refreshToken.value);
            };

            const changeAccessToken = async () => {
                await updateAuthTokens('fiw83h938hr8w3rh', refreshToken.value);
            };

            const changeRefreshToken = async () => {
                await updateAuthTokens(accessToken.value, 'diao32rhoihr32o');
            };

            const deactivateDevice = async (token: any) => {
                await AppAPI.deactivateDevice(token).catch((err) => {
                    console.log(err);
                });
                await getDevices();
            };

            const resetWelcomeMessage = () => {
                SecureStoragePlugin.set({ key: 'welcomePopup', value: 'yes' });
            };

            const resetReviewStatus = () => {
                SecureStoragePlugin.set({ key: 'reviewStatus', value: '' });
            };

            const openSupport = () => {
                Intercom.displayMessenger();
            };

            const getPlatform = () => {
                return Capacitor.getPlatform();
            };

            watch(
                () => notificationToggleValue.value,
                (val) => {
                    disableNotifications();
                }
            );

            onMounted(async () => {
                await AppFunctions.getUserInfo();
                await getDevices();
                if (store.DisableNotifications == 'yes') {
                    notificationToggleValue.value = true;
                } else {
                    notificationToggleValue.value = false;
                }
            });

            return {
                ionRouter,
                phonePortrait,
                book,
                settings,
                deviceList,
                notificationToggleValue,
                appVersion,
                deviceShowAmount,
                store,
                deviceListNextLink,
                ModalController,
                AppAPI,
                ellipsisVerticalCircleOutline,
                informationCircle,
                accessToken,
                refreshToken,
                logData,
                disableNotifications,
                getDevices,
                showMoreDevices,
                openDeviceSettings,
                logout,
                updateRefreshToken,
                showWelcomeMessage,
                clearRefreshToken,
                clearAccessToken,
                changeAccessToken,
                changeRefreshToken,
                deactivateDevice,
                resetWelcomeMessage,
                resetReviewStatus,
                openSupport,
                getPlatform,
                adminAppWelcomePopup,
                housekeeperGroup,
                appBuild,
            };
        },
    });
</script>

<style scoped>
    ion-avatar {
        --border-radius: 4px;
        background: #fff;
    }
    .ldx-header-icon {
        padding-right: 20px;
        position: relative;
        margin: -8px;
        height: 30px;
        width: 30px;
        margin-left: -5px;
    }

    ion-button {
        --background: var(--ion-color-primary);
        --border-radius: 10px;
        --background-activated: var(--ion-color-primary-tint);
    }

    ion-item {
        --background: transparent;
        padding: 0px !important;
        margin: 0px;
    }

    ion-list {
        --background: transparent;
        padding: 0px !important;
        margin: 5px;
    }
</style>
