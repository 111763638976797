import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_grid_vue = _resolveComponent("ag-grid-vue")!

  return (_openBlock(), _createBlock(_component_ag_grid_vue, {
    rowData: _ctx.rowData,
    columnTypes: _ctx.columnTypes,
    columnDefs: _ctx.colDefs,
    style: {"height":"100%"},
    theme: _ctx.themeBalham,
    overlayNoRowsTemplate: _ctx.overlayNoRowsTemplate
  }, null, 8, ["rowData", "columnTypes", "columnDefs", "theme", "overlayNoRowsTemplate"]))
}