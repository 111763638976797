<template>
    <base-layout page-title="Reports" whiteBackground>
        <ion-list lines="full">
            <template v-for="(reports, category) in groupedReports" :key="category">
                <ion-item-divider>
                    <ion-label style="text-transform: uppercase">{{ category }}</ion-label>
                </ion-item-divider>
                <ion-item
                    v-for="(item, index) in reports"
                    :key="index"
                    @click="openModal(item.modal)"
                    style="cursor: pointer"
                >
                    <ion-label>
                        {{ item.name }}
                    </ion-label>
                    <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
                </ion-item>
            </template>
        </ion-list>

        <div
            v-if="reportsList.length === 0"
            style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%"
        >
            <ion-icon :icon="documentText" style="width: 50px; height: 50px" />
            <ion-label style="text-align: center; margin-top: 10px; font-weight: bold">No reports available.</ion-label>
        </div>
    </base-layout>
</template>

<script lang="ts">
    import AppAPI from '@/services/AppAPI';
    import ModalController from '@/services/ModalController';
    import { useStore } from '@/store/store';
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { chevronForwardOutline, documentText } from 'ionicons/icons';

    export default defineComponent({
        components: {},

        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const reportsList = ref([] as any);

            onMounted(async () => {
                reportsList.value = (await AppAPI.getReportsList()).data;
                reportsList.value = formatData(reportsList.value);
            });

            const formatData = (reportData: any) => {
                const formattedReports: {
                    name: any;
                    modal: any;
                    category: any;
                }[] = [];

                reportData.forEach((report: any) => {
                    report.sections.forEach((section: any) => {
                        section.items.forEach((item: any) => {
                            formattedReports.push({
                                name: item.text,
                                modal: item.href,
                                category: section.header,
                            });
                        });
                    });
                });

                return formattedReports;
            };

            const groupedReports = computed(() => {
                return reportsList.value.reduce(
                    (acc: { [x: string]: any[] }, report: { category: string | number }) => {
                        if (!acc[report.category]) {
                            acc[report.category] = [];
                        }
                        acc[report.category].push(report);
                        return acc;
                    },
                    {} as Record<string, typeof reportsList.value>
                );
            });

            const openModal = async (modal: string) => {
                switch (modal) {
                    case '/app/reports/arrivals/':
                        await ModalController.arrivalsReportModal();
                        break;
                    case '/app/reports/departure/':
                        await ModalController.departuresReportModal();
                        break;
                    case '/app/reports/current-guests/':
                        await ModalController.currentGuestsReportModal();
                        break;
                    case '/app/reports/housekeeping/':
                        await ModalController.housekeepingReportModal();
                        break;
                    case '/app/reports/damage-notices/':
                        await ModalController.damageNoticesReportModal();
                        break;
                    default:
                        console.warn(`No modal handler for ${modal}`);
                }
            };

            return {
                ionRouter,
                store,
                chevronForwardOutline,
                groupedReports,
                ModalController,
                reportsList,
                documentText,
                openModal,
            };
        },
    });
</script>

<style scoped></style>
