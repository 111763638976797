import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "block" }
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column","align-items":"center","overflow-x":"auto","height":"100%","width":"100%"} }
const _hoisted_3 = { style: {"width":"100%","background-color":"#20469b","padding":"10px","display":"flex","flex-direction":"row","align-items":"center","justify-content":"space-between"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GridComponent = _resolveComponent("GridComponent")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: "Damage Notices Report",
    background: "rgb(239, 246, 255)",
    noPaddingBottom: "",
    customStyles: "height: 100%;"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "medium",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { style: {"max-width":"fit-content !important"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    id: "open-modal",
                    style: {"width":"fit-content !important"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        style: {"width":"24px","height":"24px"},
                        icon: _ctx.filter
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.rowData && _ctx.colDefs)
        ? (_openBlock(), _createBlock(_component_GridComponent, {
            key: 0,
            rowData: _ctx.rowData,
            colDefs: _ctx.colDefs
          }, null, 8, ["rowData", "colDefs"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_modal, {
        trigger: "open-modal",
        "initial-breakpoint": 1,
        breakpoints: [0, 1],
        ref: "damageNoticesReportFilterModal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ion_label, { style: {"color":"white","font-weight":"bold","font-size":"18px"} }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Report Filters")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_icon, {
                  style: {"color":"white","font-size":"24px","cursor":"pointer"},
                  icon: _ctx.close,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss()))
                }, null, 8, ["icon"])
              ]),
              _createVNode(_component_ion_list, {
                style: {"width":"100%"},
                lines: "full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        label: "Properties",
                        onIonChange: _ctx.fetchReport,
                        multiple: "",
                        modelValue: _ctx.propertiesSelected,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.propertiesSelected) = $event)),
                        placeholder: "All Properties"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (property) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              value: property.property_id,
                              key: property.property_id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(property.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["onIonChange", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        class: "filter-button",
                        modelValue: _ctx.timeframe,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.timeframe) = $event)),
                        label: "Timeframe",
                        onIonChange: _ctx.fetchReport
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "Yesterday" }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("Yesterday")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "Today" }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode("Today")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "Last Week" }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("Last Week")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "This Week" }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode("This Week")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "Last Month" }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode("Last Month")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "This Month" }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode("This Month")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "Last Year" }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("Last Year")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "This Year" }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode("This Year")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "Future" }, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode("Future")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onIonChange"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}