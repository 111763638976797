import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/lodgix-icon-color-2.svg'


const _hoisted_1 = { style: {"max-width":"var(--max-width)","text-align":"left","margin":"auto"} }
const _hoisted_2 = { style: {"justify-content":"center","display":"flex"} }
const _hoisted_3 = { style: {"justify-content":"center","display":"flex"} }
const _hoisted_4 = { style: {"justify-content":"center","display":"flex","padding-bottom":"20px"} }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "ldx-menu-logo",
  alt: "Logo",
  src: _imports_0,
  style: {"max-width":"250px","border-radius":"10px"}
}
const _hoisted_7 = { style: {"font-weight":"700","font-size":"large","margin":"0px","color":"white"} }
const _hoisted_8 = {
  slot: "start",
  style: {"display":"flex","align-items":"center","justify-content":"start","max-width":"70%","overflow":"hidden"}
}
const _hoisted_9 = {
  slot: "end",
  style: {"display":"flex","align-items":"center","justify-content":"start"}
}
const _hoisted_10 = { style: {"font-weight":"700","font-size":"large","margin":"0px","color":"white"} }
const _hoisted_11 = { style: {"font-weight":"700","font-size":"large","margin":"0px","color":"white"} }
const _hoisted_12 = { style: {"font-weight":"bolder"} }
const _hoisted_13 = { style: {"font-weight":"bolder"} }
const _hoisted_14 = { style: {"font-weight":"bolder"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, { "page-title": "Account" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_content, { style: {"padding-left":"0px","padding-right":"0px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, {
                  class: "ldx-section-start",
                  style: {"padding-right":"0px","padding-bottom":"0px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (_ctx.store.userEmail != '' && _ctx.store.userEmail != null)
                        ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                            key: 0,
                            style: {"font-size":"small","text-align":"center","font-weight":"700"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.store.userEmail), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.store.userEmail == '')
                        ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                            key: 1,
                            style: {"font-size":"small","font-weight":"700"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.store.companyEmail), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.store.userFirstName) + " " + _toDisplayString(_ctx.store.userLastName), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_ion_thumbnail, { style: {"width":"65%","height":"fit-content","display":"flex","justify-content":"center"} }, {
                        default: _withCtx(() => [
                          (_ctx.store.companyLogo != '' && _ctx.store.companyLogo != null)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "ldx-menu-logo",
                                alt: "Logo",
                                src: _ctx.store.companyLogo,
                                style: {"max-width":"250px","border-radius":"10px"}
                              }, null, 8, _hoisted_5))
                            : _createCommentVNode("", true),
                          (_ctx.store.companyLogo == '')
                            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_list, { style: {"padding":"0px","margin":"0px"} }, {
                  default: _withCtx(() => [
                    (_ctx.store.companyName != '' && _ctx.store.companyName != null)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 0,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { style: {"margin":"0px","font-size":"medium","max-width":"40%"} }, {
                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                _createElementVNode("b", null, "Company:", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, {
                              style: {"margin":"0px","text-align":"right"},
                              "text-wrap": ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.store.companyName), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.store.subscriberId != '' && _ctx.store.subscriberId != null)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 1,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { style: {"margin":"0px","font-size":"medium","max-width":"40%"} }, {
                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                _createElementVNode("b", null, "Subscriber ID:", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, {
                              style: {"margin":"0px","text-align":"right"},
                              "text-wrap": ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.store.subscriberId), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.store.companyWebsite != '' && _ctx.store.companyWebsite != null)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 2,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { style: {"margin":"0px","font-size":"medium","padding":"0px","max-width":"24%"} }, {
                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createElementVNode("b", null, "Website:", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, {
                              style: {"margin":"0px","text-align":"right","padding":"0px"},
                              "text-wrap": ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.store.companyWebsite), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.store.companyPhone != '' && _ctx.store.companyPhone != null)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 3,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { style: {"margin":"0px","padding":"0px","font-size":"medium","max-width":"24%"} }, {
                              default: _withCtx(() => _cache[17] || (_cache[17] = [
                                _createElementVNode("b", null, "Phone:", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, {
                              style: {"margin":"0px","padding":"0px","text-align":"right"},
                              "text-wrap": ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.store.companyPhone), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.store.loginType != '' && _ctx.store.loginType != null)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 4,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { style: {"margin":"0px","padding":"0px","font-size":"medium","max-width":"24%"} }, {
                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                _createElementVNode("b", null, "Account:", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, {
                              style: {"margin":"0px","padding":"0px","text-align":"right"},
                              "text-wrap": ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.store.loginType), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (
                            _ctx.getPlatform() != 'web' &&
                            (_ctx.store.loginType === 'Subscriber' ||
                                _ctx.store.loginType === 'Front Desk' ||
                                _ctx.store.loginType === 'Super User')
                        )
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSupport())),
                      color: "primary",
                      style: {"width":"-webkit-fill-available","--border-radius":"10px","margin-left":"10px","margin-right":"10px"}
                    }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode("Contact Support")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, { style: {"background":"linear-gradient(118deg, #0d47a1, rgba(13, 71, 161, 0.7))","margin":"0px","padding-bottom":"15px"} }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_7, [
                  _createVNode(_component_ion_icon, {
                    class: "ldx-header-icon",
                    src: _ctx.phonePortrait
                  }, null, 8, ["src"]),
                  _cache[20] || (_cache[20] = _createTextVNode("Devices "))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, { style: {"padding-top":"10px"} }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviceList, (item, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    (index < _ctx.deviceShowAmount || _ctx.deviceShowAmount == -1)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 0,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.name), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                              (item.registration_id === _ctx.store.pushNotificationToken)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 0,
                                    style: {"margin":"0"},
                                    color: "white",
                                    id: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.informationCircle,
                                        color: "primary",
                                        style: {"min-height":"20px","min-width":"20px"}
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 2
                                  }, 1032, ["id"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_ion_icon, {
                                style: {"min-height":"20px","min-width":"20px","cursor":"pointer"},
                                onClick: ($event: any) => (_ctx.ModalController.deviceEditModal(item)),
                                icon: _ctx.ellipsisVerticalCircleOutline
                              }, null, 8, ["onClick", "icon"])
                            ]),
                            (item.registration_id === _ctx.store.pushNotificationToken)
                              ? (_openBlock(), _createBlock(_component_ion_popover, {
                                  key: 0,
                                  trigger: item.id,
                                  side: "top",
                                  alignment: "center"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_content, {
                                      class: "ion-padding",
                                      style: {"--background":"white","text-align":"center"}
                                    }, {
                                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                                        _createTextVNode("Current Device")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["trigger"]))
                              : _createCommentVNode("", true),
                            false
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 1,
                                  onClick: ($event: any) => (_ctx.AppAPI.deleteDevice(item.registration_id))
                                }, {
                                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                                    _createTextVNode("Delete")
                                  ])),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128)),
                ((_ctx.deviceListNextLink || _ctx.deviceShowAmount > 0) && _ctx.deviceList?.length > 0)
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 0,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMoreDevices()))
                        }, {
                          default: _withCtx(() => _cache[23] || (_cache[23] = [
                            _createTextVNode("View More")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.deviceList.length > 0)
                  ? (_openBlock(), _createBlock(_component_ion_label, {
                      key: 1,
                      "text-wrap": "",
                      style: {"margin-left":"20px"}
                    }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode("Devices may appear multiple times.")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.deviceList.length <= 0)
                  ? (_openBlock(), _createBlock(_component_ion_label, {
                      key: 2,
                      style: {"display":"flex","justify-content":"center","padding-top":"20px","margin-bottom":"20px","font-weight":"700","font-size":"large"}
                    }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode("No Devices")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, { style: {"background":"linear-gradient(118deg, #0d47a1, rgba(13, 71, 161, 0.7))","margin":"0px","padding-bottom":"15px"} }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_10, [
                  _createVNode(_component_ion_icon, {
                    class: "ldx-header-icon",
                    src: _ctx.settings
                  }, null, 8, ["src"]),
                  _cache[26] || (_cache[26] = _createTextVNode("Settings "))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, { style: {"padding-top":"10px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_toggle, {
                      modelValue: _ctx.notificationToggleValue,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.notificationToggleValue) = $event)),
                      "label-placement": "start"
                    }, {
                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                        _createTextVNode("Disable Notification Warning")
                      ])),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                false
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 0,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[28] || (_cache[28] = [
                            _createTextVNode("Reset Welcome Message:")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          expand: "full",
                          color: "primary",
                          slot: "end",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetWelcomeMessage()))
                        }, {
                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                            _createTextVNode("Reset")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPlatform() != 'web' && (_ctx.housekeeperGroup() || _ctx.adminAppWelcomePopup()))
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 1,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { "text-wrap": "" }, {
                          default: _withCtx(() => _cache[30] || (_cache[30] = [
                            _createTextVNode("Welcome Message")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          color: "primary",
                          style: {"--border-radius":"10px","width":"20%","padding":"5px 0"},
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showWelcomeMessage()))
                        }, {
                          default: _withCtx(() => _cache[31] || (_cache[31] = [
                            _createTextVNode("Open")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                false
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 2,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[32] || (_cache[32] = [
                            _createTextVNode("Reset Review Status:")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          expand: "full",
                          color: "primary",
                          slot: "end",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetReviewStatus()))
                        }, {
                          default: _withCtx(() => _cache[33] || (_cache[33] = [
                            _createTextVNode("Reset")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPlatform() != 'web')
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 3,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          color: "primary",
                          style: {"width":"100%","--border-radius":"10px","padding":"5px 0"},
                          onClick: _ctx.openDeviceSettings
                        }, {
                          default: _withCtx(() => _cache[34] || (_cache[34] = [
                            _createTextVNode("Open Device Settings")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, { style: {"background":"linear-gradient(118deg, #0d47a1, rgba(13, 71, 161, 0.7))","margin":"0px","padding-bottom":"15px"} }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_11, [
                  _createVNode(_component_ion_icon, {
                    class: "ldx-header-icon",
                    src: _ctx.book
                  }, null, 8, ["src"]),
                  _cache[35] || (_cache[35] = _createTextVNode("App Information "))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, { style: {"padding-top":"10px"} }, {
              default: _withCtx(() => [
                _cache[38] || (_cache[38] = _createElementVNode("p", null, "Lodgix Admin", -1)),
                _cache[39] || (_cache[39] = _createElementVNode("p", null, [
                  _createElementVNode("a", { href: "https://www.lodgix.com/privacy-policy/" }, "Privacy Policy")
                ], -1)),
                _cache[40] || (_cache[40] = _createElementVNode("p", null, [
                  _createElementVNode("a", { href: "https://www.lodgix.com/terms-of-service/" }, "Terms of Service")
                ], -1)),
                _createElementVNode("p", null, [
                  _cache[36] || (_cache[36] = _createElementVNode("b", null, "Version: ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.appVersion), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[37] || (_cache[37] = _createElementVNode("b", null, "Build: ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.appBuild), 1)
                ]),
                _createElementVNode("p", null, "© Lodgix " + _toDisplayString(new Date().getFullYear()), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.store.debug)
          ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => _cache[41] || (_cache[41] = [
                        _createTextVNode("OAuth2 Information")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_subtitle, { style: {"font-size":"small","font-weight":"bold"} }, {
                      default: _withCtx(() => _cache[42] || (_cache[42] = [
                        _createTextVNode("Developer Only")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => _cache[43] || (_cache[43] = [
                        _createTextVNode("Access Token")
                      ])),
                      _: 1
                    }),
                    _cache[51] || (_cache[51] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("code", _hoisted_12, _toDisplayString(_ctx.accessToken), 1),
                    _cache[52] || (_cache[52] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => _cache[44] || (_cache[44] = [
                        _createTextVNode("Refresh Token")
                      ])),
                      _: 1
                    }),
                    _cache[53] || (_cache[53] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("code", _hoisted_13, _toDisplayString(_ctx.refreshToken), 1),
                    _cache[54] || (_cache[54] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_component_ion_button, {
                      color: "primary",
                      expand: "full",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.logData()))
                    }, {
                      default: _withCtx(() => _cache[45] || (_cache[45] = [
                        _createTextVNode("LogData")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      color: "primary",
                      expand: "full",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateRefreshToken()))
                    }, {
                      default: _withCtx(() => _cache[46] || (_cache[46] = [
                        _createTextVNode("Renew Access Token")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      color: "primary",
                      expand: "full",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeAccessToken()))
                    }, {
                      default: _withCtx(() => _cache[47] || (_cache[47] = [
                        _createTextVNode("Change Access Token")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      color: "primary",
                      expand: "full",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clearAccessToken()))
                    }, {
                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode("Clear Access Token")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      color: "primary",
                      expand: "full",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.clearRefreshToken()))
                    }, {
                      default: _withCtx(() => _cache[49] || (_cache[49] = [
                        _createTextVNode("Clear Refresh Token")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      color: "primary",
                      expand: "full",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.changeRefreshToken()))
                    }, {
                      default: _withCtx(() => _cache[50] || (_cache[50] = [
                        _createTextVNode("Change Refresh Token")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.debug)
          ? (_openBlock(), _createBlock(_component_ion_card, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => _cache[55] || (_cache[55] = [
                        _createTextVNode("Push Notification Information")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_subtitle, { style: {"font-size":"small","font-weight":"bold"} }, {
                      default: _withCtx(() => _cache[56] || (_cache[56] = [
                        _createTextVNode("Developer Only")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => _cache[57] || (_cache[57] = [
                        _createTextVNode("Token")
                      ])),
                      _: 1
                    }),
                    _cache[59] || (_cache[59] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("code", _hoisted_14, _toDisplayString(_ctx.store.pushNotificationToken), 1),
                    _cache[60] || (_cache[60] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_component_ion_button, {
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.deactivateDevice(_ctx.store.pushNotificationToken)))
                    }, {
                      default: _withCtx(() => _cache[58] || (_cache[58] = [
                        _createTextVNode("Deactivate Device")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_button, {
          style: {"width":"-webkit-fill-available","--border-radius":"10px","margin":"0 15px 30px 15px"},
          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.logout())),
          color: "danger"
        }, {
          default: _withCtx(() => _cache[61] || (_cache[61] = [
            _createTextVNode("Sign Out")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}