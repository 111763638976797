<template>
    <div>
        <div class="info-row" v-if="info.housekeeping_notes">* Housekeep: {{ info.housekeeping_notes }}</div>
        <div class="text-danger info-row" v-if="info.to_date == info.next_in_from_date">
            ** Turnover ** Arrival @ {{ info.nfcheckin }} ({{ info.next_in_meta_reservation_pk }})
        </div>
        <div class="info-row" v-if="info.to_date == info.next_in_from_date">
            Arriving Guest: {{ info.next_in_guest
            }}<span v-if="info.next_in_total_guests">, {{ info.next_in_total_guests }}</span>
        </div>
        <div class="info-row" v-else-if="info.next_in_guest">
            Next Ck-In: {{ info.next_in_from_date_str }} {{ info.nfcheckin
            }}<span v-if="info.next_in_guest">, {{ info.next_in_guest }}</span
            ><span v-if="info.next_in_total_guests">, {{ info.next_in_total_guests }}</span>
        </div>
        <div class="info-row" v-else>Next Ck-In: None</div>
        <div class="info-row" v-if="info.next_block_reason">* Block Reason: {{ info.next_block_reason }}</div>
        <div class="info-row" v-if="info.cservices">Services: {{ info.cservices }}</div>
        <div class="info-row" v-if="info.next_in_has_pets">Pets: {{ info.next_in_number_of_pets }}</div>
    </div>
</template>

<script>
    export default {
        data() {
            return { info: {} };
        },
        created() {
            this.info = this.params.data;
        },
    };
</script>
