import { errorToast, successToast } from '@/services/Toast';
import ControlPanelModal from '@/components/reservation/controlPanel/ControlPanelModal.vue';
import NotificationModal from '@/components/notifications/modals/NotificationModal.vue';
import GuestSelectModal from '@/components/reservation/modals/GuestSelectModal.vue';
import PropertySelectionModal from '@/components/reservation/modals/PropertySelectionModal.vue';
import { modalController } from '@ionic/vue';
import { hideLoading, showLoading } from '@/services/Loading';
import AppAPI from '@/services/AppAPI';
import AppFunctions from '@/services/AppFunctions';
import { useStore } from '@/store/store';
import RulesOverrideModalVue from '@/components/reservation/modals/RulesOverrideModal.vue';
import WidgetEditModalVue from '@/components/widgets/modals/WidgetEditModal.vue';
import DeviceEditModal from '@/components/settings/modals/DeviceEditModal.vue';
import WorkOrderDetailsModal from '@/components/workOrders/modals/WorkOrderDetailsModal.vue';
import WorkOrderDetailsMaterialExpenseModal from '@/components/workOrders/modals/WorkOrderDetailsMaterialExpenseModal.vue';
import GuestDetailsModal from '@/components/guests/modals/GuestDetailsModal.vue';
import ReservationFilterModal from '@/components/reservation/modals/ReservationFilterModal.vue';
import InvoiceNotesModal from '@/components/reservation/modals/InvoiceNotesModal.vue';
import WorkOrderVendorsModal from '@/components/workOrders/modals/WorkOrderVendorsModal.vue';
import ArrivalsReportModal from '@/components/reports/modals/ArrivalsReportModal.vue';
import DeparturesReportModal from '@/components/reports/modals/DeparturesReportModal.vue';
import CurrentGuestsReportModal from '@/components/reports/modals/CurrentGuestsReportModal.vue';
import DamageNoticesReportModal from '@/components/reports/modals/DamageNoticesReportModal.vue';
import HousekeepingReportModal from '@/components/reports/modals/HousekeepingReportModal.vue';

export default {
    async controlPanelModal(invoiceNumber = '' as string | number, techReservationId = '') {
        if (techReservationId) {
            const response = await AppAPI.getInvoiceNumber(techReservationId);
            if (response?.data && response.data?.invoice_number) {
                invoiceNumber = response.data.invoice_number.toString();
            } else {
                errorToast('Cannot load reservation. Please try again later.');
                return;
            }
        }
        if (typeof invoiceNumber === 'number') {
            invoiceNumber = invoiceNumber.toString();
        }
        const loadingService = await showLoading('Loading...');
        try {
            const modal = await modalController.create({
                component: ControlPanelModal,
                componentProps: {
                    invoiceNumber: invoiceNumber,
                },
            });

            hideLoading(loadingService);
            modal.present();

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { data, role } = await modal.onWillDismiss();
            if (role === 'confirm') {
                //console.log(data);
            }
        } catch (error) {
            hideLoading(loadingService);
            errorToast('Reservation not found');
            return;
        }
    },
    async notificationModal(item: any) {
        const store = useStore();
        store.changePushNotificationOpenId('');
        const modal = await modalController.create({
            component: NotificationModal,
            componentProps: {
                item: item,
            },
        });
        modal.present();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, role } = await modal.onWillDismiss();
        if (role === 'cancel') {
            store.changePushNotificationOpenId('');
        } else if (role == 'confirm') {
            store.changePushNotificationOpenId('');
            await AppAPI.setReadPushNotification(item.id);
            await successToast('Notification marked as read');
        }
        await AppFunctions.updatePushNotificationSystem();
    },
    async guestSelectModal() {
        const modal = await modalController.create({
            component: GuestSelectModal,
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            return data;
        } else {
            return null;
        }
    },
    async propertyListModal(isBlock: boolean) {
        const modal = await modalController.create({
            component: PropertySelectionModal,
            componentProps: {
                isBlock: isBlock,
            },
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            //console.log(data)
        }
        if (role === 'cancel') {
            if (data.error) {
                return 'error';
            }
        }
    },

    async rulesOverrideModal(errors: any) {
        const modal = await modalController.create({
            component: RulesOverrideModalVue,
            componentProps: {
                errors: errors.response.data.errors,
            },
        });
        modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async customRulesOverrideModal(errors: any) {
        const modal = await modalController.create({
            component: RulesOverrideModalVue,
            componentProps: {
                errors: errors,
            },
        });
        modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async widgetEditModal() {
        const modal = await modalController.create({
            component: WidgetEditModalVue,
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async deviceEditModal(device: any) {
        const modal = await modalController.create({
            component: DeviceEditModal,
            componentProps: {
                device: device,
            },
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async workOrderDetailsModal(workOrderId: string) {
        const modal = await modalController.create({
            component: WorkOrderDetailsModal,
            componentProps: {
                workOrderId: workOrderId,
            },
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async workOrderDetailsMaterialExpenseModal(workOrderId: any, workOrderData: any) {
        const modal = await modalController.create({
            component: WorkOrderDetailsMaterialExpenseModal,
            componentProps: {
                workOrderId: workOrderId,
                data: workOrderData,
            },
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async reservationFilterModal(currentFilters: any) {
        const modal = await modalController.create({
            component: ReservationFilterModal,
            componentProps: {
                currentFilters: currentFilters,
            },
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async editInvoiceNotesModal(notes: string) {
        const modal = await modalController.create({
            component: InvoiceNotesModal,
            componentProps: {
                notes: notes,
            },
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            return data;
        } else {
            return null;
        }
    },

    async guestDetailsModal(guestId?: number) {
        const modal = await modalController.create({
            component: GuestDetailsModal,
            componentProps: {
                guestId: guestId,
            },
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async workOrderVendorsModal() {
        const modal = await modalController.create({
            component: WorkOrderVendorsModal,
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async arrivalsReportModal() {
        const modal = await modalController.create({
            component: ArrivalsReportModal,
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            return data;
        } else {
            return null;
        }
    },

    async departuresReportModal() {
        const modal = await modalController.create({
            component: DeparturesReportModal,
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            return data;
        } else {
            return null;
        }
    },

    async currentGuestsReportModal() {
        const modal = await modalController.create({
            component: CurrentGuestsReportModal,
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            return data;
        } else {
            return null;
        }
    },

    async damageNoticesReportModal() {
        const modal = await modalController.create({
            component: DamageNoticesReportModal,
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            return data;
        } else {
            return null;
        }
    },

    async housekeepingReportModal() {
        const modal = await modalController.create({
            component: HousekeepingReportModal,
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            return data;
        } else {
            return null;
        }
    },
};
