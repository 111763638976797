<template>
    <ag-grid-vue
        :rowData="rowData"
        :columnTypes="columnTypes"
        :columnDefs="colDefs"
        style="height: 100%"
        :theme="themeBalham"
        :overlayNoRowsTemplate="overlayNoRowsTemplate"
    ></ag-grid-vue>
</template>

<script lang="ts">
    import { computed, defineComponent, ref } from 'vue';
    import { themeBalham } from 'ag-grid-community';
    import isNil from 'lodash/isNil';
    import { toAmountDisplay } from '@/utils/Numbers';
    import { format, parseISO } from 'date-fns';
    import { renderGuestStatusIcon } from '@/utils/constants';
    import { useStore } from '@/store/store';

    export default defineComponent({
        components: {},
        props: {
            rowData: {
                type: Array,
                required: true,
            },
            colDefs: {
                type: Array,
                required: true,
            },
            overlayNoRowsTemplate: {
                type: String,
                default: 'Nothing found',
            },
        },

        setup(props, ctx) {
            const store = useStore();

            const columnTypes = computed(() => {
                return {
                    amountColumn: {
                        cellRenderer: ({
                            value,
                            noNegative,
                            percentage = false,
                        }: {
                            value: any;
                            noNegative?: boolean;
                            percentage?: boolean;
                        }) => {
                            if (!isNil(value) && !isNaN(value)) {
                                return toAmountDisplay({
                                    amount: value,
                                    currency: store.userCurrencySymbol,
                                    noNegative,
                                    percentage,
                                });
                            }
                        },
                        cellClass: 'ag-right-aligned-cell',
                        headerClass: 'ag-right-aligned-header',
                        minWidth: 90,
                        sortable: true,
                    },
                    dateColumn: {
                        cellRenderer: ({ value, noValue }: { value: any; noValue: any }) => {
                            return value ? format(parseISO(value), store.userUnicodeDateFormat) : noValue || '';
                        },
                    },
                    dateTimeColumn: {
                        cellRenderer: ({ value, noValue }: { value: any; noValue: any }) => {
                            return value
                                ? format(
                                      parseISO(value),
                                      `${store.userUnicodeDateFormat} ${store.userTimeFormat.toLocaleLowerCase()}`
                                  )
                                : noValue || '';
                        },
                    },
                    guestStatusColumn: {
                        cellRenderer: ({ value }: { value: any }) => (value ? renderGuestStatusIcon(value) : ''),
                        cellClass: 'guest-status-cell',
                        maxWidth: 70,
                        minWidth: 70,
                    },
                };
            });

            return {
                themeBalham,
                columnTypes,
            };
        },
    });
</script>

<style scoped>
    :deep(.guest-status-cell) {
        .dashboard-guest-status-icon {
            width: 24px;
            height: 23px;
            margin-right: 0.5rem;
        }
    }
</style>
