import { Decimal } from 'decimal.js';
import isNil from 'lodash/isNil';
import commaNumber from 'comma-number';

export const toAmountDisplay = ({
    amount,
    currency = null,
    noNegative = false,
    integer = false,
    withComma = true,
    decimalPlaces = 2,
    percentage = false,
}: {
    amount: any;
    currency?: string | null;
    noNegative?: boolean;
    integer?: boolean;
    withComma?: boolean;
    decimalPlaces?: number;
    percentage?: boolean;
}) => {
    if (isNil(amount) || amount === '') {
        return '';
    }

    const currencySymbol: string = currency ? currency : '';
    const japaneseYenCurrencySymbol = '\u00a5';

    if (currencySymbol === japaneseYenCurrencySymbol) {
        decimalPlaces = 0;
    }

    let number;
    if (integer) {
        number = parseInt(amount);
    } else {
        number = Decimal.abs(amount).toFixed(decimalPlaces, Decimal.ROUND_HALF_EVEN);
    }
    if (withComma) {
        number = commaNumber(number);
    }

    const isPositive = Number(amount) >= 0;
    if (percentage) {
        return isPositive ? `${number}%` : `${noNegative ? '' : '-'}${number}%`;
    }
    return isPositive ? `${currencySymbol}${number}` : `${noNegative ? '' : '-'}${currencySymbol}${number}`;
};
