<template>
    <base-layout page-title="Documents" whiteBackground style="overflow: hidden; height: 100%">
        <div
            v-if="showDocuments"
            style="height: 100%; padding-bottom: env(safe-area-inset-bottom) !important; overflow: hidden"
        >
            <file-manager
                :key="fileManagerKey"
                :owner-id="owner.id"
                :features="features"
                :read-only="userIsOwner"
            ></file-manager>
        </div>
    </base-layout>
</template>

<script lang="ts">
    import { accessToken } from '@/services/OAuth2';
    import { computed, defineAsyncComponent, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import { ownerGroup } from '@/types/accessGroups';

    const FileManager = defineAsyncComponent(() => import('../FileManager.vue'));

    export default defineComponent({
        components: {
            FileManager,
        },
        setup() {
            const owner = ref({ id: 0 });
            const fileManagerKey = ref(0);
            const store = useStore();
            const userIsOwner = ref(ownerGroup());
            const showDocuments = ref(false);

            onMounted(() => {
                owner.value = { id: Number(store.id) };
                fileManagerKey.value += 1;
                showDocuments.value = true;
            });

            const features = computed(() => {
                return userIsOwner.value ? ['download', 'preview', 'upload', 'delete'] : null;
            });

            return {
                showDocuments,
                owner,
                fileManagerKey,
                accessToken,
                features,
                userIsOwner,
            };
        },
    });
</script>
