import {
    calendarNumberOutline,
    calendarClearOutline,
    listOutline,
    calendarOutline,
    chatbubblesOutline,
    notificationsOutline,
    home,
    calendarNumber,
    calendarClear,
    list,
    calendar,
    chatbubbles,
    notifications,
    people,
    personCircle,
    lockClosed,
    construct,
    constructOutline,
    barChart,
    documentTextOutline,
    documentText,
} from 'ionicons/icons';
import reservationUnconfirmed from '@/assets/icons/reservationUnconfirmed.vue';
import reservationCancelled from '@/assets/icons/reservationCancelled.vue';
import reservationConfirmed from '@/assets/icons/reservationConfirmed.vue';
import reservationPaid from '@/assets/icons/reservationPaid.vue';
import { sub, add } from 'date-fns';

export const webRedirectUrlLocal = 'http://localhost:8100/';
export const webRedirectUrl = 'https://app.lodgix.com/';
export const appId = 'p7DMYI5yMgfCV7crTi4nmRAXiz6XgNkjdbl7xZus';

export const mainMenu = [
    {
        label: 'Create Reservation',
        icon: calendarNumberOutline,
        color: 'success',
        url: '/createReservation',
        scopes: ['reservation:write'],
        roles: [],
    },
    {
        label: 'Create Block',
        icon: calendarClearOutline,
        color: 'secondary',
        url: '/createBlock',
        scopes: ['reservation:write'],
        roles: [],
    },
    {
        label: 'View Reservations',
        icon: listOutline,
        color: 'warning',
        url: '/reservationsList',
        scopes: ['reservation:read'],
        roles: [],
    },
    {
        label: 'Calendar Tape',
        icon: calendarOutline,
        color: 'primary',
        url: '/calendarPage',
        scopes: ['calendar:read', 'calendar:write'],
        roles: [],
    },
    {
        label: 'Unified Inbox',
        icon: chatbubblesOutline,
        color: 'dark',
        url: '/unifiedInbox',
        scopes: ['inbox:read', 'inbox:write'],
        roles: [],
    },
    {
        label: 'Work Orders',
        icon: constructOutline,
        color: 'tertiary',
        url: '/workOrders',
        scopes: ['workorder:read'],
        roles: [],
    },
    {
        label: 'Documents',
        icon: documentTextOutline,
        color: 'medium',
        url: '/owner/documents',
        scopes: [],
        roles: ['Owner Default', 'Owner Minimal', 'Owner Enhanced', 'Owner No Work Orders'],
    },
    {
        label: 'Notifications',
        icon: notificationsOutline,
        color: 'danger',
        url: '/notifications',
        scopes: [],
        roles: [],
    },
];

export const sideMenu = [
    {
        title: 'Home',
        id: 'HOME',
        url: '/home',
        iosIcon: home,
        mdIcon: home,
        keyWords: 'home',
        scopes: [],
        roles: [],
    },
    {
        title: 'Create Reservation',
        id: 'CREATE_RESERVATION',
        url: '/createreservation',
        iosIcon: calendarNumber,
        mdIcon: calendarNumber,
        keyWords: 'create reservation booking new reservation',
        scopes: ['reservation:write'],
        roles: [],
    },
    {
        title: 'Create Block',
        id: 'CREATE_BLOCK',
        url: '/createBlock',
        iosIcon: calendarClear,
        mdIcon: calendarClear,
        keyWords: 'create block blocking new blocking property blocking',
        scopes: ['reservation:write'],
        roles: [],
    },
    {
        title: 'View Reservations',
        id: 'VIEW_RESERVATIONS',
        url: '/reservationsList',
        iosIcon: list,
        mdIcon: list,
        keyWords: 'view reservations calendar guest reservations all reservations all blocking',
        scopes: ['reservation:read'],
        roles: [],
    },
    {
        title: 'View Guests',
        id: 'VIEW_GUESTS',
        url: '/guests',
        iosIcon: people,
        mdIcon: people,
        keyWords: 'guests',
        scopes: ['guest:read'],
        roles: [],
    },
    {
        title: 'Calendar Tape',
        id: 'CALENDAR_TAPE',
        url: '/calendarPage',
        iosIcon: calendar,
        mdIcon: calendar,
        keyWords: 'calendar tape view calendar all reservations all blocking',
        scopes: ['calendar:read', 'calendar:write'],
        roles: [],
    },
    {
        title: 'Unified Inbox',
        id: 'UNIFIED_INBOX',
        url: '/unifiedInbox',
        iosIcon: chatbubbles,
        mdIcon: chatbubbles,
        keyWords: 'chat unified inbox messages',
        scopes: ['inbox:read', 'inbox:write'],
        roles: [],
    },
    {
        title: 'Work Orders',
        id: 'WORK_ORDERS',
        url: '/workOrders',
        iosIcon: construct,
        mdIcon: construct,
        keyWords: 'work orders',
        scopes: ['workorder:read'],
        roles: [],
    },
    {
        title: 'Reports',
        id: 'REPORTS',
        url: '/reports',
        iosIcon: barChart,
        mdIcon: barChart,
        keyWords: 'reports',
        scopes: [],
        roles: [],
    },
    {
        title: 'Documents',
        id: 'DOCUMENTS',
        url: '/owner/documents',
        iosIcon: documentText,
        mdIcon: documentText,
        keyWords: 'documents files',
        scopes: [],
        roles: ['Owner Default', 'Owner Minimal', 'Owner Enhanced', 'Owner No Work Orders'],
    },
    {
        title: 'Notifications',
        id: 'NOTIFICATIONS',
        url: '/notifications',
        iosIcon: notifications,
        mdIcon: notifications,
        keyWords: 'notifications alert',
        scopes: [],
        roles: [],
    },
    {
        title: 'Support',
        id: 'SUPPORT',
        url: '/support',
        iosIcon: people,
        mdIcon: people,
        keyWords: 'help customer support chat documents intercom lodgix support',
        scopes: [],
        roles: ['Subscriber', 'Super User', 'Front Desk'],
    },
    {
        title: 'Account',
        id: 'ACCOUNT',
        url: '/account',
        iosIcon: personCircle,
        mdIcon: personCircle,
        keyWords: 'account information signout about version logout profile support help customer service',
        scopes: ['userinfo:read', 'userinfo:write'],
        roles: [],
    },
    {
        title: 'Sign Out',
        id: 'SIGN_OUT',
        url: '/signout',
        iosIcon: lockClosed,
        mdIcon: lockClosed,
        keyWords: 'signout logout',
        scopes: [],
        roles: [],
    },
];

export const workOrderPriorityColors = {
    CRITICAL: '#cf3c4f',
    URGENT: '#eb445a',
    HIGH: '#f56800',
    MEDIUM: '#ffc409',
    LOW: '#6370ff',
    PROJECT: '#1055c4',
    DEFAULT: '#1055c4',
};

export const workOrderStatusColors = {
    OPEN: '#ffc409',
    CANCELLED: '#eb445a',
    ESCALATED: '#eb445a',
    CLOSED: '#4caf50',
    DEFAULT: '#1055c4',
};

export const timeframeCalculations: { [key: string]: () => { fromDate: Date; toDate: Date } } = {
    Today: () => {
        const date = new Date();
        return { fromDate: date, toDate: date };
    },
    Yesterday: () => {
        const date = sub(new Date(), { days: 1 });
        return { fromDate: date, toDate: date };
    },
    Tomorrow: () => {
        const date = add(new Date(), { days: 1 });
        return { fromDate: date, toDate: date };
    },
    'Last 7 Days': () => {
        const fromDate = sub(new Date(), { days: 7 });
        const toDate = new Date();
        return { fromDate, toDate };
    },
    'Next 7 Days': () => {
        const fromDate = new Date();
        const toDate = add(fromDate, { days: 7 });
        return { fromDate, toDate };
    },
    'Last 14 Days': () => {
        const fromDate = sub(new Date(), { days: 14 });
        const toDate = new Date();
        return { fromDate, toDate };
    },
    'Next 14 Days': () => {
        const fromDate = new Date();
        const toDate = add(fromDate, { days: 14 });
        return { fromDate, toDate };
    },
    'Last 30 Days': () => {
        const fromDate = sub(new Date(), { days: 30 });
        const toDate = new Date();
        return { fromDate, toDate };
    },
    'Next 30 Days': () => {
        const fromDate = new Date();
        const toDate = add(fromDate, { days: 30 });
        return { fromDate, toDate };
    },
    'Last Week': () => {
        const fromDate = sub(new Date(), { weeks: 1 });
        const toDate = new Date();
        return { fromDate, toDate };
    },
    'This Week': () => {
        const fromDate = new Date();
        const toDate = add(new Date(), { weeks: 1 });
        return { fromDate, toDate };
    },
    'Last Month': () => {
        const fromDate = sub(new Date(new Date().getFullYear(), new Date().getMonth(), 1), { months: 1 });
        const toDate = sub(add(fromDate, { months: 1 }), { days: 1 });
        return { fromDate, toDate };
    },
    'This Month': () => {
        const fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const toDate = sub(add(fromDate, { months: 1 }), { days: 1 });
        return { fromDate, toDate };
    },
    'Last Year': () => {
        const fromDate = sub(new Date(new Date().getFullYear(), 0, 1), { years: 1 });
        const toDate = add(fromDate, { years: 1 });
        return { fromDate, toDate };
    },
    'This Year': () => {
        const fromDate = new Date(new Date().getFullYear(), 0, 1);
        const toDate = add(fromDate, { years: 1 });
        return { fromDate, toDate };
    },
    Default: () => {
        const fromDate = new Date();
        const toDate = add(new Date(), { years: 25 });
        return { fromDate, toDate };
    },
};

export const controlPanelStatusMap = (props: any) => {
    return {
        PAID: {
            class: 'statusCard',
            style: 'background: rgba(20, 170, 0, 0.2)',
            titleStyle:
                'font-size: x-large; color: rgb(2, 183, 106); font-weight: bold; align-items: center; display: flex;',
            imgStyle: 'width: 30px; margin-right: 10px',
            title: props.substate === 'PAID' ? 'Paid In Full' : 'Paid',
            icon: reservationPaid,
        },
        PAIDDEPOSIT: {
            class: 'statusCard',
            style: 'background: rgba(243, 174, 61, 0.2)',
            titleStyle:
                'font-size: x-large; color: rgb(239, 172, 2); font-weight: bold; align-items: center; display: flex;',
            imgStyle: 'width: 30px; margin-right: 10px',
            title: 'Confirmed',
            icon: reservationConfirmed,
        },
        NOTPAID: {
            class: 'statusCard',
            style: 'background: rgba(255, 0, 0, 0.2)',
            titleStyle:
                'font-size: x-large; color: rgb(255, 93, 93); font-weight: bold; align-items: center; display: flex;',
            imgStyle: 'width: 30px; margin-right: 10px',
            title: 'Unconfirmed',
            icon: reservationUnconfirmed,
        },
        CANCELLED: {
            class: 'statusCard',
            style: 'background: rgba(127, 127, 127, 0.2)',
            titleStyle:
                'font-size: x-large; color: rgb(116, 116, 116); font-weight: bold; align-items: center; display: flex;',
            imgStyle: 'width: 30px; margin-right: 10px',
            title: 'Cancelled',
            icon: reservationCancelled,
        },
    };
};

export function isLocalDevelopment() {
    return (
        process.env.VUE_APP_URL_BASE != 'https://www.lodgix.com' &&
        process.env.VUE_APP_URL_BASE != 'https://staging.lodgix.com'
    );
}

export const renderGuestStatusIcon = (status: any) => {
    const statusImageSrc = status.is_vip
        ? '/assets/icons/vip.svg'
        : status.is_blocklist
        ? '/assets/icons/blocklist.svg'
        : null;
    if (status && statusImageSrc) {
        return `<img class="dashboard-guest-status-icon" title="${status.name}" src="${statusImageSrc}" />`;
    }
};

export const escapeHTML = (str: any) => {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;',
    };

    return String(str).replace(/[&<>"'`=/]/g, (s: string) => {
        return entityMap[s as keyof typeof entityMap];
    });
};

const _getWindowWidth = () => window.innerWidth || document.body.clientWidth || document.documentElement.clientWidth;

export const isLargeScreen = () => {
    const lg = 996;
    return _getWindowWidth() >= lg;
};

export const isMediumScreen = () => {
    const md = 768;
    return _getWindowWidth() >= md;
};

export const isMobileScreen = () => {
    const md = 768;
    return _getWindowWidth() < md;
};
