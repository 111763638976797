<template>
    <base-layout page-title="Create Reservation">
        <div style="max-width: var(--max-width); text-align: left; margin: auto">
            <div
                style="
                    background-color: white;
                    margin: 15px;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 10%);
                    border-color: black;
                    border-width: 1px;
                "
                class=""
            >
                <!--
                    Guest Section
                -->
                <GuestComponent></GuestComponent>

                <!--
                    Date Section
                -->
                <DateSelectorComponent v-bind="dateProps"></DateSelectorComponent>

                <!--
                    Property Section
                -->
                <PropertyComponent v-bind="isMinimal"></PropertyComponent>

                <!--
                    Extra Info Section
                -->
                <ion-card-header style="padding-bottom: 10px" class="ldx-section-start">
                    <ion-card-title>Extra Information</ion-card-title>
                </ion-card-header>
                <ion-label style="font-weight: 700">Reservation Type</ion-label>
                <ion-list class="ldx-reservation-form">
                    <ion-item lines="none">
                        <ion-select
                            :interface-options="reservationTypeOptions"
                            interface="alert"
                            v-model="ReservationType"
                            style="cursor: pointer"
                        >
                            <ion-select-option value="GUEST">Guest</ion-select-option>
                            <ion-select-option value="OWNER">Owner</ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-list>
            </div>

            <ion-card v-if="store.reservationSelectedPropertyId != ''">
                <ion-card-content>
                    <!--
                        QUOTES
                    -->
                    <QuoteComponent></QuoteComponent>

                    <!--
                        Payment Button
                    -->
                    <div class="content-card-home">
                        <ion-button
                            v-if="isAllComplete()"
                            @click="submitTemporaryReservation()"
                            style="width: 100%; --border-radius: 10px"
                            color="primary"
                            >Continue</ion-button
                        >
                        <ion-button
                            v-if="!isAllComplete()"
                            @click="submitError()"
                            style="width: -webkit-fill-available; --border-radius: 10px"
                            color="primary"
                            >Continue</ion-button
                        >
                    </div>
                </ion-card-content>
            </ion-card>
        </div>
    </base-layout>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, ref } from 'vue';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import CommonFunctions from '@/services/AppFunctions';
    import { format } from 'date-fns';
    import { showLoading, hideLoading } from '@/services/Loading';
    import GuestComponent from '@/components/reservation/components/GuestComponent.vue';
    import DateSelectorComponent from '@/components/reservation/components/DateSelectorComponent.vue';
    import PropertyComponent from '@/components/reservation/components/PropertyComponent.vue';
    import QuoteComponent from '@/components/reservation/components/QuoteComponent.vue';
    import ModalController from '@/services/ModalController';
    import { basicAlert } from '@/services/AlertController';

    export default defineComponent({
        name: 'CreateReservations',
        components: {
            GuestComponent,
            DateSelectorComponent,
            PropertyComponent,
            QuoteComponent,
        },
        setup() {
            const ionRouter = useIonRouter();
            const store = useStore();
            const ReservationType = ref('GUEST');
            const isError = ref(false);
            const isSubmitted = ref(false);
            const guestNameError = ref(false);
            const isMinimal = ref({ isMinimal: false });
            const dateProps = ref({
                TitleLabel: 'Arrival/Departure',
                StartDateLabel: 'Arrival Date',
                EndDateLabel: 'Departure Date',
            });

            const reservationTypeOptions = {
                header: 'Reservation Type',
                subHeader: '',
                message: '',
                translucent: true,
            };

            const submitError = () => {
                basicAlert('', '', 'Please enter necessary information before continuing.');
                if (
                    store.reservationFirstName == '' ||
                    store.reservationLastName == '' ||
                    store.reservationEmail == '' ||
                    !validateEmail(store.reservationEmail)
                ) {
                    guestNameError.value = true;
                } else {
                    guestNameError.value = false;
                }
            };

            const validateEmail = (email: string) => {
                var isRealEmail = CommonFunctions.validateEmail(email);
                return isRealEmail;
            };

            const getDisplayMoney = (value: number) => {
                return value.toFixed(2);
            };

            const isAllComplete = () => {
                if (
                    store.reservationFirstName != '' &&
                    store.reservationLastName != '' &&
                    store.reservationEmail != '' &&
                    store.reservationStartDate != '' &&
                    store.reservationEndDate != '' &&
                    store.reservationSelectedPropertyId != '' &&
                    ReservationType.value != '' &&
                    validateEmail(store.reservationEmail)
                ) {
                    return true;
                } else {
                    return false;
                }
            };

            const submitTemporaryReservation = async (force = false) => {
                const loadingService = await showLoading('Loading...');
                try {
                    store.changeReservationReservationType(ReservationType.value);

                    if (store.reservationAdults == '' || store.reservationAdults == null) {
                        store.changeReservationAdults('1');
                    }
                    if (store.reservationChildren == '' || store.reservationChildren == null) {
                        store.changeReservationChildren('0');
                    }
                    if (store.reservationPets == '' || store.reservationPets == null) {
                        store.changeReservationPets('0');
                    }

                    var fromDateString = format(new Date(store.reservationStartDate), 'yyyy-MM-dd');
                    var toDateString = format(new Date(store.reservationEndDate), 'yyyy-MM-dd');

                    var response = AppAPI.sendTempReservation(
                        store.reservationSelectedPropertyId,
                        store.reservationFirstName,
                        store.reservationLastName,
                        store.reservationEmail,
                        fromDateString,
                        toDateString,
                        store.reservationAdults,
                        store.reservationChildren,
                        store.reservationPets,
                        force,
                        store.reservationGuestId,
                        store.reservationType
                    );
                    response
                        .then((value: any) => {
                            store.changeReservationInvoiceId(value.data.id);
                            store.changeReservationSetupIntentClientSecret(value.data.setup_intent_client_secret);
                            store.changeReservationStripePublishableKey(value.data.stripe_publishable_key);
                            store.changeReservationStripeAccount(value.data.stripe_account);
                            store.changeReservationConfirmationAmount(value.data.confirmation_amount);
                            store.changeReservationTotalWithDeposits(value.data.total_with_deposits);
                            store.changeReservationCustomAmount('');

                            if (value.data.guest.address.state != null) {
                                store.changeReservationBillingState(value.data.guest.address.state.id);
                            } else {
                                store.changeReservationBillingState('');
                            }
                            store.changeReservationBillingAddress(value.data.guest.address.address1);
                            store.changeReservationBillingCity(value.data.guest.address.city);
                            store.changeReservationBillingZip(value.data.guest.address.zip);
                            store.changeReservationBillingCountry(value.data.guest.address.country.id);

                            AppAPI.getCountries().then((value) => {
                                store.changeReservationBillingCountryList(value.data);
                            });

                            AppAPI.getStates(value.data.guest.address.country.id).then((value) => {
                                store.changeReservationBillingStateList(value.data);
                            });

                            hideLoading(loadingService);
                            ionRouter.replace('/cart');
                        })
                        .catch(async (errors) => {
                            hideLoading(loadingService);
                            try {
                                const result = await ModalController.rulesOverrideModal(errors);
                                if (result.data) {
                                    submitTemporaryReservation(true);
                                    return;
                                }
                            } catch {
                                isError.value = true;
                                basicAlert(
                                    'Error with Submission',
                                    '',
                                    'Please review information and make sure that it is correct and try again.'
                                );
                            }
                        });

                    isError.value = false;
                } catch (error) {
                    hideLoading(loadingService);
                }
            };

            return {
                reservationTypeOptions,
                ionRouter,
                ReservationType,
                isError,
                isSubmitted,
                guestNameError,
                isMinimal,
                dateProps,
                store,
                submitError,
                validateEmail,
                getDisplayMoney,
                isAllComplete,
                submitTemporaryReservation,
            };
        },
        methods: {},
    });
</script>

<style scoped>
    .ldx-section-start {
        padding-top: 0;
        padding-left: 0;
    }

    .content-section {
        padding-bottom: 10px;
    }
    .ldx-section-start ion-card-title {
        font-size: 20px;
    }
    .ldx-reservation-form ion-item::part(native) {
        background: var(--ion-color-light-tint);
        border: 1px solid var(--ion-color-medium-tint);
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        margin-bottom: 0.75rem;
        height: 44px;
    }

    .ldx-reservation-form ion-select::part(placeholder),
    .ldx-reservation-form ion-select::part(text),
    .ldx-reservation-form ion-select::part(icon) {
        color: #000000;
        opacity: 1;
    }
    .ldx-reservation-form ion-select::part(icon) {
        right: 0rem;
        position: absolute;
    }

    .ldx-reservation-form ion-icon.ldx-alt-icon {
        background: var(--ion-color-primary);
        color: #ffffff;
        padding: 0.5rem;
        font-size: 15px;
        border-radius: 5px;
        margin: 0;
    }
</style>
